import { ActionsUnion, createAction } from '@housinganywhere/safe-redux';

import { QueryOptions } from '@ha/api/v2/inventory/getFavoriteUnitTypes';
import { FavoriteListing } from '@ha/api/v2/types/FavoriteListing';
import { Listing } from '@ha/api/v2/types/Listing';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { getIsAuthenticated, getUserId } from 'ha/modules/AuthLogic/selectors';
import { Thunk } from 'ha/myredux/types';
import { GlobalState } from 'ha/types/store';

import { isFetchStarted } from '../selectors';

export enum ActionTypes {
  START = '[favorites] LOAD_FAVORITES',
  DONE = '[favorites] LOAD_FAVORITES_DONE',
  FAIL = '[favorites] LOAD_FAVORITES_FAIL',
}
export const Actions = {
  start: () => createAction(ActionTypes.START),
  done: (favorites: FavoriteListing[]) =>
    createAction(ActionTypes.DONE, favorites),
  fail: () => createAction(ActionTypes.FAIL),
};
export type ActionsUnionType = ActionsUnion<typeof Actions>;

interface GetFavoriteListingOptions {
  expand: QueryOptions['expand'];
}

type FetchFavoriteListings = (
  options?: GetFavoriteListingOptions,
) => Thunk<GlobalState>;
export const getFavoriteListings: FetchFavoriteListings =
  options =>
  (dispatch, getState, { apiV2 }) => {
    const state = getState();

    if (!getIsAuthenticated(state)) {
      return Promise.resolve();
    }
    if (isFetchStarted(state)) {
      return Promise.resolve();
    }

    dispatch(Actions.start());

    return apiV2
      .getFavoriteUnitTypes({
        query: {
          userId: getUserId(state)!,
          expand: options?.expand,
        },
        fetch: {
          keepalive: true,
        },
      })
      .then(({ data }) => {
        const unitTypeFavorites = data.favorites;
        const favorites = unitTypeFavorites.map(favorite => {
          const listing = {
            ...favorite.unitType,
            listingPath: favorite.unitType?.unitTypePath,
            unitTypeId: favorite.unitType?.id,
          } as Listing;

          return {
            ...favorite,
            listingId: favorite.unitTypeId || 0,
            listing,
          };
        });

        dispatch(Actions.done(favorites));
      })
      .catch(error => {
        reportError(
          new Error('Error getting favourite listing', { cause: error }),
        );

        dispatch(Actions.fail());
      });
  };
