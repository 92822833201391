import { MakeCall } from '../createMakeCall';
import { PropertyPhoto, PropertyPhotoType } from '../types/inventory/Property';

export interface UpdateUnitTypePhotoOptions {
  rotate?: number;
  rotateIncrement?: number;
  type?: PropertyPhotoType;
}

const updatePropertyPhoto =
  (makeCall: MakeCall) =>
  (
    propertyId: number,
    photoId: number,
    { rotate, rotateIncrement, type }: UpdateUnitTypePhotoOptions,
  ) =>
    makeCall<PropertyPhoto[]>(
      'PUT',
      `/api/v2/property/${propertyId}/photo/${photoId}`,
      {
        data: {
          rotate,
          rotateIncrement,
          type,
        },
      },
    );
export { updatePropertyPhoto };
