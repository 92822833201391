/* eslint-disable camelcase */
export enum PropertyType {
  studio = 'STUDIO',
  apartment = 'APARTMENT',
  house = 'HOUSE',
  building = 'BUILDING',
  privateRoom = 'PRIVATE_ROOM',
  sharedRoom = 'SHARED_ROOM',
  unknown = 'UNKNOWN',
}

export enum LandlordType {
  STUDENT = 'student',
  PRIVATE_LANDLORD = 'private-landlord',
  RENTAL_COMPANY = 'rental-company',
  STUDENT_COMPANY = 'student-company',
  OTHER = 'other',
}

export type ContractType = 'daily' | 'fortnightly' | 'monthly' | 'unknown';

export interface AlgoliaListing {
  _geoloc: {
    lat: number;
    lng: number;
  };
  advertiserAvatar: string;
  advertiserId: number;
  advertiserFirstName: string;
  advertiserMoveInOverallRating: number;
  advertiserMoveInReviewCount: number;
  advertiserResponseRate: number;
  advertiserResponseTime: number;
  apartmentId: string;
  city: string;
  country: string;
  creationDate: string;
  creationDateTS: number;
  currency: string;
  dateFrom: string;
  dateFromTS: number;
  dateTo: string;
  dateToTS: number;
  description: string;
  facility_ac: string;
  exclusivityPartnerIDs: number[];
  facility_allergy_friendly: string;
  facility_animal_allowed: string;
  facility_balcony_terrace: string;
  facility_basement: string;
  facility_bathroom: string;
  facility_bed: string;
  facility_bedroom_count: string;
  facility_bedroom_furnished: string;
  facility_co_living: string;
  facility_student_housing: string;
  facility_bedroom_size: string;
  facility_closet: string;
  facility_desk: string;
  facility_dishwasher: string;
  facility_dryer: string;
  facility_flooring: string;
  facility_garden: string;
  facility_heating: string;
  facility_housemates_gender: string;
  facility_internet: string;
  facility_kitchen: string;
  facility_kitchenware: string;
  facility_living_room: string;
  facility_lock: string;
  facility_lroom_furniture: string;
  facility_parking: string;
  facility_play_music: string;
  facility_registration_possible: string;
  facility_smoking_allowed: string;
  facility_tenant_status: string;
  facility_toilet: string;
  facility_total_size: string;
  facility_tv: string;
  facility_washing_machine: string;
  facility_wheelchair_accessible: string;
  facility_wifi: string;
  firstDate: string;
  hasAvatar: boolean;
  hasPhotos: boolean;
  internalID: number;
  isApartmentAggregation: boolean;
  isExcellentAdvertiser: boolean;
  isMultiuse: boolean;
  isPartner: boolean;
  isPartnerBalanceOK: boolean;
  isSearchable: boolean;
  lastDate: string;
  listingScore: number;
  maxBookableDays: number;
  minimumStayMonths: number;
  maximumStay: number;
  currentOccupancy: number;
  freePlaces: number;
  path: string;
  photoCount: number;
  floorPlanCount: number;
  videoCount: number;
  photos?: string[];
  price: number;
  priceEUR: number;
  rank_simple: number;
  slug: string;
  street: string;
  thumbnailURL: string;
  validConversations: number;
  zip: string;
  objectID: string;
  id: number;
  entityId: number;
  advertiser: {
    avatarURL: string;
  };
  listingPath: string;
  previewImage: string;
  isNew: boolean;
  minimalRentalPeriod: number;
  exclusions: Array<{
    from: string;
    to: string;
  }>;
  latitude: number;
  longitude: number;
  cPolicy: 'strict' | 'flexible' | '30d-movein-flexible';
  utilities: 'I' | 'E' | 'P';
  propertyType: PropertyType;
  minPrice: number;
  maxPrice: number;
  priceType: 'flat' | 'monthly';
  contractType: ContractType;
  isCharitable: boolean;
  isMultiUnit: boolean;
  unitTypePath?: string;
  unitTypeInternalID: number;
  rentalPeriodType: 'flexible' | 'strict';
  bookableDateFromStart: string;
  bookableDateFromEnd: string;
  bookableDateToStart: string;
  bookableDateToEnd: string;
  bookableDateFromStartTS: number;
  bookableDateFromEndTS: number;
  bookableDateToStartTS: number;
  bookableDateToEndTS: number;
  pricingValues: {
    flat: number;
    monthly: Record<string, number> | null;
    precise?: Record<string, number>;
  };
  pricingValuesEUR: {
    flat: number;
    monthly: Record<string, number> | null;
    precise?: Record<string, number>;
  };
  landlordType?: LandlordType;
  photo360Count?: number;
  has3DTour?: boolean;
  rank_withOrpheus?: number;
  rank_withOrpheusExperiment?: number;
}
