import { MakeCall } from '../createMakeCall';
import { Property, UpdatePropertyPayload } from '../types/inventory/Property';

const updateProperty =
  (makeCall: MakeCall) => (propertyId: number, data: UpdatePropertyPayload) =>
    makeCall<Property>('PUT', `/api/v2/property/${propertyId}`, {
      data,
    });

export { updateProperty };
