import { MakeCall } from './createMakeCall';
import { Unit } from './types/inventory/Unit';

export interface Query {
  /**
   * @description comma-separated string with possible values: `exclusions`, `exclusionsExtended`, `bookablePeriods`, `conversationCounters`, `lastMonthConversationCount`, `photos`, `videos`, `costs`
   */
  expand?: string;
  lang?: string;
}

export interface UpdateUnitICSPayload {
  icsUrl?: string;
}

const updateUnitICS =
  (makeCall: MakeCall) =>
  (unitId: number, data: UpdateUnitICSPayload, query?: Query) =>
    makeCall<Unit>('PUT', `/api/v2/listing/${unitId}`, {
      data,
      query,
    });

export { updateUnitICS };
