import React from 'react';

import { HAMobileAppMetadata, OSType } from './types';
import { isVersionGreaterOrEqual } from './utils';

export type MobileAppContextData = {
  isHAMobileApp: boolean;
} & Partial<HAMobileAppMetadata>;

export type MobileAppContextValues = MobileAppContextData & {
  isVersionGreaterOrEqual: (version: string) => boolean;
};

const MobileAppContext = React.createContext<MobileAppContextValues>({
  isHAMobileApp: false,
  appVersion: '',
  appBuild: '',
  osType: OSType.UNKNOWN,
  isVersionGreaterOrEqual: (_version: string) => false,
});

export const MobileAppProvider: React.FC<
  React.PropsWithChildren<{ value: MobileAppContextData }>
> = ({ value, children }) => {
  const enhancedValue = React.useMemo<MobileAppContextValues>(() => {
    return {
      ...value,
      isVersionGreaterOrEqual: (version: string) => {
        if (!value.isHAMobileApp || !value.appVersion) {
          return false;
        }
        return isVersionGreaterOrEqual({
          currentVersion: value.appVersion,
          compareVersion: version,
        });
      },
    };
  }, [value]);
  return (
    <MobileAppContext.Provider value={enhancedValue}>
      {children}
    </MobileAppContext.Provider>
  );
};

/**
 * Prepares the mobile app context by parsing the user agent string to determine app-specific information.
 *
 * @param userAgent - Optional browser/device user agent string to parse
 *
 * @returns MobileAppContextValues object containing:
 *  - isHAMobileApp: Whether the current context is within the HA mobile app
 *  - appVersion: Version number of the HA mobile app (e.g. "1.2.3")
 *  - appBuild: Build number of the HA mobile app
 *  - osType: Operating system type (IOS, ANDROID, or UNKNOWN)
 *
 * @example
 * const context = prepareMobileAppContext(navigator.userAgent);
 * if (context.isHAMobileApp) {
 *   console.log(`Running in HA mobile app version ${context.appVersion}`);
 * }
 */
export const prepareMobileAppContext = (
  userAgent?: string,
): MobileAppContextData => {
  const contextValues: MobileAppContextData = {
    isHAMobileApp: false,
    appVersion: '',
    appBuild: '',
    osType: OSType.UNKNOWN,
  };

  try {
    if (!userAgent) return contextValues;

    contextValues.isHAMobileApp = Boolean(
      userAgent.match(/HousingAnywhereApp\/[0-9]+\.[0-9]+\.[0-9]+/),
    );

    if (!contextValues.isHAMobileApp) return contextValues;

    const versionMatch = userAgent.match(
      /HousingAnywhereApp\/([0-9]+\.[0-9]+\.[0-9]+)\(?([0-9]+)?\)?/,
    );

    if (!versionMatch) return contextValues;

    contextValues.appVersion = versionMatch[1] || '';
    contextValues.appBuild = versionMatch[2] || '';

    const lowerCaseUA = userAgent.toLowerCase();
    if (lowerCaseUA.includes('iphone') || lowerCaseUA.includes('ipad')) {
      contextValues.osType = OSType.IOS;
    } else if (lowerCaseUA.includes('android')) {
      contextValues.osType = OSType.ANDROID;
    } else {
      contextValues.osType = OSType.UNKNOWN;
    }

    return contextValues;
  } catch {
    return contextValues;
  }
};

export const useHAMobileApp = () => React.useContext(MobileAppContext);
