import isEmpty from 'lodash-es/isEmpty';

import { TouchPointsList } from '@ha/api/v2/types/Affiliates';

import { getIsAuthenticated } from 'ha/modules/AuthLogic/selectors';
import { Thunk } from 'ha/myredux/types';

import { touchPoints } from '../utils/touchPoints';

export const sendTouchPointsList =
  (touchPointsList: TouchPointsList | {}): Thunk =>
  (_, getState, { apiV2 }) => {
    const isAuthenticated = getIsAuthenticated(getState());

    if (isEmpty(touchPointsList) || !isAuthenticated) {
      return undefined;
    }

    return apiV2
      .sendTouchPointsList(touchPointsList)
      .then(() => touchPoints.clear())
      .catch((error: unknown) => {
        throw error;
      });
  };
