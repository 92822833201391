import { apiV2 } from '@ha/api/v2';
import { MakeCallPromise } from '@ha/api/v2/createMakeCall';

import { Environment } from 'ha/config';

function isCacheSupportedDataType(data: unknown): data is DataType {
  if (!data || typeof data !== 'object') return false;

  return 'headers' in data && 'status' in data && 'data' in data;
}

type RequestKeys = keyof ReturnType<typeof apiV2>;

type DataType = Awaited<MakeCallPromise<unknown>>;

type RequestCacheEntry = {
  data: DataType;
  timestamp: number;
  params: unknown[];
};

export type RequestCacheData = Map<RequestKeys, RequestCacheEntry>;

export class RequestCache {
  private cache: RequestCacheData;

  private ongoingRequests: Map<RequestKeys, Promise<unknown>>;

  private _disableCache: boolean;

  constructor() {
    this.cache = new Map();
    this.ongoingRequests = new Map();
    this._disableCache = process.env.NODE_ENV === Environment.TEST;

    if (global && global.__CACHED_DATA__) {
      this.initializeCache(global.__CACHED_DATA__);
    }
  }

  set disableCache(value: boolean) {
    this._disableCache = value;
  }

  private initializeCache(
    cachedData: Record<
      string,
      { data: unknown; timestamp: number; params: unknown[] }
    >,
  ): void {
    for (const [key, { data, timestamp, params }] of Object.entries(
      cachedData,
    )) {
      this.cache.set(key as RequestKeys, {
        data: data as DataType,
        timestamp,
        params,
      });
    }
  }

  serializeCache(): string {
    const cachedData = this.getCachedQueries().reduce((acc, key) => {
      const cacheEntry = this.cache.get(key);

      if (cacheEntry) {
        acc[key] = {
          data: cacheEntry.data,
          timestamp: cacheEntry.timestamp,
          params: cacheEntry.params,
        };
      }
      return acc;
    }, {} as Record<string, RequestCacheEntry>);

    return JSON.stringify(cachedData);
  }

  private static generateBaseKey(
    queryKey: [RequestKeys, ...unknown[]],
  ): RequestKeys {
    return queryKey[0];
  }

  async fetchQuery<T, A extends unknown[]>(
    queryKey: [RequestKeys, ...A],
    callback: (...args: A) => Promise<T>,
    options?: { staleTime?: number },
  ): Promise<T> {
    if (this._disableCache) {
      return callback(...(queryKey.slice(1) as A));
    }

    const baseKey = RequestCache.generateBaseKey(queryKey);
    const now = Date.now();

    // Check cache first
    const cacheEntry = this.cache.get(baseKey);

    if (cacheEntry) {
      const paramsChanged =
        JSON.stringify(cacheEntry.params) !== JSON.stringify(queryKey.slice(1));
      const cacheIsExpired =
        options?.staleTime && now - cacheEntry.timestamp >= options.staleTime;

      if (!paramsChanged && !cacheIsExpired) {
        return cacheEntry.data as T;
      }
    }

    if (this.ongoingRequests.has(baseKey)) {
      return this.ongoingRequests.get(baseKey) as Promise<T>;
    }

    try {
      const requestPromise = callback(...(queryKey.slice(1) as A));
      this.ongoingRequests.set(baseKey, requestPromise);

      const data = await requestPromise;

      if (isCacheSupportedDataType(data)) {
        const { headers, ...clonedDataForCache } = data as DataType;

        if (data.status === 200) {
          this.cache.set(baseKey, {
            data: clonedDataForCache,
            timestamp: now,
            params: queryKey.slice(1),
          });
        }
      }

      return data;
    } finally {
      this.ongoingRequests.delete(baseKey);
    }
  }

  invalidateQuery(queryKey: [RequestKeys, ...unknown[]]): void {
    const baseKey = RequestCache.generateBaseKey(queryKey);
    this.cache.delete(baseKey);
  }

  clearCache(): void {
    this.cache.clear();
    this.ongoingRequests.clear();
  }

  getCachedQueries(): RequestKeys[] {
    return Array.from(this.cache.keys());
  }

  getCachedQuery<T>(queryKey: RequestKeys): T | undefined {
    const cacheEntry = this.cache.get(queryKey);

    if (!cacheEntry) {
      return undefined;
    }
    return cacheEntry.data.data as T;
  }
}

export const createRequestCacheService = (): RequestCache => {
  return new RequestCache();
};
