import { trace, Context, SpanOptions, Tracer, Span } from '@opentelemetry/api';

import { Environment } from 'ha/config';

import { config } from '../../setup';
import { isBrowser } from '../isBrowser';

const TRACE_ENABLED: boolean | undefined =
  config?.traceClient.enabled ?? process.env.TRACE_CLIENT_ENABLED;
const TRACE_SERVICE_NAMESPACE: string | undefined =
  config?.traceClient.serviceNamespace ?? process.env.TRACE_SERVICE_NAMESPACE;
const TRACE_SERVICE_NAME: string | undefined = TRACE_SERVICE_NAMESPACE
  ? `${TRACE_SERVICE_NAMESPACE}-${isBrowser() ? 'browser' : 'server'}`
  : undefined;
const TRACE_SERVICE_VERSION: string | undefined =
  config?.traceClient.serviceVersion ?? process.env.TRACE_SERVICE_VERSION;

interface OptionalTracer {
  startSpan(): Span | undefined;
}

interface NoopTracer extends OptionalTracer {
  startActiveSpan<F extends (span: Span | undefined) => unknown>(
    name: string,
    fn: F,
  ): ReturnType<F>;
  startActiveSpan<F extends (span: Span | undefined) => unknown>(
    name: string,
    options: SpanOptions,
    fn: F,
  ): ReturnType<F>;
  startActiveSpan<F extends (span: Span | undefined) => unknown>(
    name: string,
    options: SpanOptions,
    context: Context,
    fn: F,
  ): ReturnType<F>;
}

export function getCurrentSpan() {
  return trace.getActiveSpan();
}

const NOOP_TRACER = {
  startActiveSpan: (_, ...args) => {
    return (args.at(-1) as () => void)();
  },
  startSpan: () => undefined,
} as NoopTracer;

export function getTracer() {
  // tracing within tests is not allowed
  if (process.env.NODE_ENV === Environment.TEST) return NOOP_TRACER;
  // service name is required and tracing must be on - otherwise we provide a dummy tracer
  if (!TRACE_ENABLED || !TRACE_SERVICE_NAME) return NOOP_TRACER;

  return trace.getTracer(
    TRACE_SERVICE_NAME,
    TRACE_SERVICE_VERSION,
  ) as OptionalTracer & Tracer;
}

export function withSelfClosingSpan<
  Func extends (...args: unknown[]) => unknown,
>(fun: Func) {
  return (span?: Span) => {
    const result = fun();

    if (result instanceof Promise) {
      return result.finally(() => span?.end()) as ReturnType<Func>;
    }

    span?.end();
    return result as ReturnType<Func>;
  };
}
