import { MakeCall } from '../createMakeCall';

const deletePropertyVideo =
  (makeCall: MakeCall) => (propertyId: number, videoId: number) =>
    makeCall<undefined>(
      'DELETE',
      `/api/v2/property/${propertyId}/video/${videoId}`,
    );

export { deletePropertyVideo };
