import { MakeCall } from '../createMakeCall';
import { PropertyPhoto } from '../types/inventory/Property';

export interface UpdatePropertyPhotoOptions {
  order: number[];
  rotate?: number;
}

const updatePropertyPhotosOrder =
  (makeCall: MakeCall) =>
  (propertyId: number, { order, rotate }: UpdatePropertyPhotoOptions) =>
    makeCall<PropertyPhoto[]>('PUT', `/api/v2/property/${propertyId}/photos`, {
      data: {
        order,
        ...(rotate ? { rotate } : {}),
      },
    });

export { updatePropertyPhotosOrder };
