import { MakeCall } from './createMakeCall';
import { City } from './types/City';

export interface SearchCityParamsBase {
  languages: string[];
}

export interface SearchCityByGeonameId extends SearchCityParamsBase {
  geonameId: number;
}

export interface SearchCityByQuery extends SearchCityParamsBase {
  query: string;
}

export type SearchCityParams = SearchCityByGeonameId | SearchCityByQuery;

export interface SearchCityData {
  [key: string]: City;
}

export type SearchCityCall = (
  data: SearchCityParams,
) => Promise<{ data: SearchCityData }>;

const searchCity = (makeCall: MakeCall) => (data: SearchCityParams) =>
  makeCall<SearchCityData>('POST', `/api/v2/geonames/search-city`, {
    data,
  });
export { searchCity };
