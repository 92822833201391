import { createSelector } from 'reselect';

import { IdentityVerificationStatus } from '@ha/api/v2/types/User';
import { Countries } from '@ha/core/SupportedCountries';
import { getDay, getMonth, getYear } from '@ha/date';

import { LandlordType, UserType } from 'ha/constants/UserType';

import { GlobalState } from 'ha/types/store';

import {
  isEnabled,
  STATE_NAME as FEATURE_FLAGS_STATE_NAME,
} from '../FeatureFlags';

import {
  CONFIRMED_IDENTITY_VERIFICATION_STATES,
  conversationAccessFeature,
  paymentRequestAccessFeature,
} from './constants';

const getAuth = (s: GlobalState) => s.authLogic;

export const getUser = (s: GlobalState) => s.authLogic.user;

export const getUserInformation = (s: GlobalState) =>
  s.authLogic.userInformation;

export const getUserFullName = (s: GlobalState) => {
  const user = getUser(s);

  if (!user) {
    return null;
  }

  return `${user.firstName} ${user.lastName}`;
};

export const getCurrentLanguage = (s: GlobalState) => getAuth(s).language;

export const getIsAuthenticated = (s: GlobalState) =>
  getAuth(s).isAuthenticated;

export const getUserPhone = (s: GlobalState) =>
  s.authLogic.user ? s.authLogic.user.phone : null;

export const getUserFirstName = (s: GlobalState) =>
  s.authLogic.user ? s.authLogic.user.firstName : null;

export const getUserLastName = (s: GlobalState) =>
  s.authLogic.user ? s.authLogic.user.lastName : null;
export const getUserType = (s: GlobalState) =>
  s.authLogic.user ? s.authLogic.user.type : null;
export const getUserEmail = (s: GlobalState) =>
  s.authLogic.user ? s.authLogic.user.email : null;
export const getCountryCode = (s: GlobalState) =>
  s.authLogic.user ? s.authLogic.user.countryCode : null;
export const getUserId = (s: GlobalState) =>
  s.authLogic.user ? s.authLogic.user.id : null;
export const getUserUUID = (s: GlobalState) =>
  s.authLogic.user ? s.authLogic.user.uuid : '';
export const getIsAdmin = (s: GlobalState) =>
  s.authLogic.user ? s.authLogic.user.isAdmin : false;
export const getIsPartner = (s: GlobalState) =>
  s.authLogic.user ? s.authLogic.user.isPartner : null;
export const getUserUniversity = (s: GlobalState) =>
  s.authLogic.user ? s.authLogic.user.study : null;
export const getUserBirthDate = (s: GlobalState) =>
  s.authLogic.user ? s.authLogic.user.birthDate : null;
export const getUserEmailVerified = (s: GlobalState) =>
  s.authLogic.user ? s.authLogic.user.isEmailVerified : null;
export const getUserEnableSMSNotifications = (s: GlobalState) =>
  s.authLogic.user ? s.authLogic.user.enableSMSNotifications : null;
export const getUserNotificationLang = (s: GlobalState) =>
  s.authLogic.user ? s.authLogic.user.notificationLang : null;
export const getUserRoomsUnderManagement = (s: GlobalState) =>
  s.authLogic.user?.roomsUnderManagement ?? null;
export const getUserBirthDay = (s: GlobalState) => {
  const date = getUserBirthDate(s);

  if (!date) {
    return date;
  }

  return getDay(date);
};
export const getUserBirthMonth = (s: GlobalState) => {
  const date = getUserBirthDate(s);

  if (!date) {
    return date;
  }

  return getMonth(date) + 1;
};
export const getUserBirthYear = (s: GlobalState) => {
  const date = getUserBirthDate(s);

  if (!date) {
    return date;
  }

  return getYear(date);
};
export const getUserSpokenLanguages = (s: GlobalState) =>
  s.authLogic.user ? s.authLogic.user.spokenLanguages : [];
export const getIsAliased = (s: GlobalState) =>
  s.authLogic.user ? s.authLogic.user.isAliased : false;

export const getUserNationality = (s: GlobalState) => {
  const { user } = s.authLogic;

  if (!user) {
    return user;
  }

  return /^[A-Z]{2}$/.test(user.nationality || '') ? user.nationality : '';
};

export const getUserAvatar = (s: GlobalState) =>
  s.authLogic.user && s.authLogic.user.avatarURL;

export const getUserCountryOfResidence = (s: GlobalState) =>
  s.authLogic.user && s.authLogic.user.countryOfResidence;

export const getResponseRate = createSelector([getUser], user =>
  user ? Math.round(user.responseRate * 100) : 0,
);

export const isResponseRateDisplaying = createSelector(
  [getUser, getUserInformation],
  (user, userInformation) =>
    !!(userInformation?.listingsCount && user?.responseRate),
);

export const getIsVerified = createSelector(
  [getIsAuthenticated, getUserType, getIsAdmin],
  (isAuthenticated, userType, isAdmin) =>
    isAuthenticated &&
    ([
      UserType.LANDLORD,
      LandlordType.STUDENT,
      LandlordType.PRIVATE_LANDLORD,
      LandlordType.RENTAL_COMPANY,
      LandlordType.STUDENT_COMPANY,
      UserType.QUARATINE,
      LandlordType.OTHER,
    ].includes(userType!) ||
      isAdmin),
);

export const getIsRealAdvertiser = createSelector(
  [getIsAuthenticated, getUserType],
  (isAuthenticated, userType) =>
    isAuthenticated &&
    [
      UserType.STUDENT,
      UserType.LANDLORD,
      UserType.PENDING,
      UserType.QUARATINE,
    ].includes(userType!),
);

export const getIsAdvertiser = createSelector(
  [getIsAuthenticated, getIsRealAdvertiser, getIsAdmin],
  (isAuthenticated, isRealAdvertiser, isAdmin) =>
    isAuthenticated && (isRealAdvertiser || isAdmin),
);

export const getIsUnderReview = createSelector(
  [getUserType],
  userType => userType === UserType.UNDER_REVIEW,
);

export const getUserIdAlias = createSelector(
  [getUserId],
  userId => `u${userId}`,
);

export const getIsUserInformationLoading = (s: GlobalState) =>
  s.authLogic.isUserBillingInfoLoading;

export const hasCompletedBookings = (s: GlobalState) =>
  s.authLogic.userInformation &&
  s.authLogic.userInformation.advertiserBookingsCount > 0;

export const isMissingBillingDetails = (s: GlobalState) =>
  s.authLogic.userInformation &&
  // eslint-disable-next-line no-prototype-builtins
  s.authLogic.userInformation.hasOwnProperty('hasCompleteBillingInformation') &&
  !s.authLogic.userInformation.hasCompleteBillingInformation;

export const isMissingPayoutMethod = (s: GlobalState) =>
  s.authLogic.userInformation &&
  s.authLogic.userInformation.savedPayoutMethodsCount === 0;

export const isKYCMissing = (state: GlobalState) =>
  state.authLogic.userInformation && !state.authLogic.userInformation.isKYCDone;

export const getHasOtherListings = (s: GlobalState) =>
  s.authLogic.userInformation && s.authLogic.userInformation.listingsCount > 1;

export const getHasListings = (state: GlobalState) =>
  (state.authLogic.userInformation &&
    state.authLogic.userInformation.listingsCount > 0) ||
  false;
export const getSubscriptionFeatures = (state: GlobalState) =>
  state.authLogic.userInformation?.subscriptionFeatures;
export const hasConversationAccessSubscription = (state: GlobalState) =>
  getSubscriptionFeatures(state)?.includes(conversationAccessFeature) ?? false;
export const hasPaymentRequestAccessSubscription = (state: GlobalState) =>
  getSubscriptionFeatures(state)?.includes(paymentRequestAccessFeature) ??
  false;

export const isAuthenticatedWithBookings = (s: GlobalState) =>
  getIsAuthenticated(s) && hasCompletedBookings(s);

export const getImpersonatingAdmin = (s: GlobalState) =>
  s.authLogic.impersonatingAdmin;

export const getUserTraits = createSelector(
  [getUser, getIsVerified, getIsAdmin, getImpersonatingAdmin],
  (user, isVerified, isAdmin, isImpersonated) => {
    if (!user) {
      return null;
    }

    return {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      usertype: user.type,
      landlordType:
        user.landlordType === LandlordType.OTHER
          ? user.landlordTypeOther
          : user.landlordType,
      phone: user.phone,
      createdAt: user.createdAt,
      verified: isVerified,
      isAdmin,
      isImpersonated: Boolean(isImpersonated),
    };
  },
);

export const getUserEnableBookingLink = createSelector(
  [getUser],
  user => user?.enableBookingLink || false,
);

export const getAnalyticsUserData = (s: GlobalState) =>
  s.authLogic.user
    ? {
        occupation: s.authLogic.user?.occupation,
        age: s.authLogic.user.birthDate
          ? new Date(s.authLogic.user.birthDate).getFullYear()
          : undefined,
        nationality: s.authLogic.user?.nationality,
        city: s.authLogic.user?.location,
        gender: s.authLogic.user?.gender,
        ...(s.authLogic.user?.uniId ? { uni_id: s.authLogic.user.uniId } : {}),
      }
    : null;

export const getUserFreeBookingsData = (s: GlobalState) => {
  if (!s.authLogic.userInformation) return null;

  // Free Commission Plan not applicable for Professional Landlords / Rental Agencies
  const isProfessional = s.authLogic.userInformation?.isProfessional;
  if (isProfessional === true || isProfessional === undefined) return null;

  const { freeBookingsUsage, nextRenewalDate } = s.authLogic.userInformation;

  // TODO - Fetch from BE (Future implementation)
  const maxFreeBookings = 5;

  return {
    maxFreeBookings,
    freeBookingsUsage,
    nextRenewalDate,
  };
};

export const getIsIcelandicLandlord = createSelector(
  [getIsAdvertiser, getUser],
  (isAdvertiser, user) =>
    isAdvertiser &&
    user?.countryOfResidence.toUpperCase() === Countries.Iceland,
);

export const getIdentityVerificationStatus = createSelector(
  [getUser],
  user => user?.idVerificationStatus,
);

export const getIsAdvertiserIdentityVerificationFeatureFlagEnabled = (
  s: GlobalState,
) => isEnabled(s[FEATURE_FLAGS_STATE_NAME].enableIdentityVerification);

export const isIdentityVerified = (
  idVerificationStatus: IdentityVerificationStatus,
) => CONFIRMED_IDENTITY_VERIFICATION_STATES.includes(idVerificationStatus);

export const getIsIdentityVerified = createSelector(
  [getIdentityVerificationStatus],
  isIdentityVerified,
);

export const getIsAdvertiserIdentityVerificationRequired = createSelector(
  [
    getIsAdvertiser,
    getIsIdentityVerified,
    getIsAdvertiserIdentityVerificationFeatureFlagEnabled,
  ],
  (
    isAdvertiser,
    isAdvertiserIdentityVerified,
    isIdentityVerificationFeatureFlagEnabled,
  ) => {
    return (
      isIdentityVerificationFeatureFlagEnabled &&
      isAdvertiser &&
      !isAdvertiserIdentityVerified
    );
  },
);
