import { IdentifyTraits, identify } from '@hbf/analytics';

import {
  INTEGRATIONS_WITHOUT_ROCKERBOX,
  ONLY_ROCKERBOX_INTEGRATION,
} from './constants';

export const identifyRockerbox = (
  userUUID: string,
  identifyPayload: IdentifyTraits,
) => {
  // Due to an issue in the Rockerbox integration, we need to send the UUID value for external_id & email attribute separately
  // this will change the cookies to the userUUID which will change the value of external_id attribute
  identify(userUUID, identifyPayload, {
    integrations: ONLY_ROCKERBOX_INTEGRATION,
    context: {
      traits: {
        email: userUUID,
      },
    },
  });
};

export const identifyWithoutRockerbox = (
  userId: string,
  identifyPayload: IdentifyTraits,
) => {
  // resets the cookies back to, userid based cookies
  identify(userId, identifyPayload, {
    integrations: INTEGRATIONS_WITHOUT_ROCKERBOX,
  });
};

export const identifyWithRockerbox = (
  userUUID: string,
  identifyPayload: IdentifyTraits,
  aliasId: string,
) => {
  identifyRockerbox(userUUID, identifyPayload);

  identifyWithoutRockerbox(aliasId, identifyPayload);
};
