import { RentalConditionsContract } from './RentalConditionsContract';
import type { RequiredDocuments } from './RequiredDocuments';
import { RequiredDocumentsFormValues } from './RequiredDocuments';

interface DateRange {
  from: string;
  to: string;
}

/**
 * @link https://github.com/housinganywhere/platform/blob/develop/api/v2/listings/api_components.yml
 */
export interface Listing {
  id: number;
  uuid: string;
  listingPath: string;
  street: string;
  housenumber: string;
  aptnumber: string;
  neighborhood: string;
  city: string;
  cityLocalized: string;
  completeSteps: boolean[];
  zip: string;
  costs: ListingCosts;
  countryCode: string;
  country: string;
  countryLocalized: string;
  address: string;
  currency: string;
  price: number;
  /** fee of HousingAnywhere - actual value */
  haFee: number;
  /** fee of HousingAnywhere - default value without any discounts */
  baseHaFee: number;
  firstDate: string;
  lastDate: string;
  firstBookableDate: string;
  lastBookableDate: string;
  /** minimum stay, in months */
  minimumStayMonths: number;
  /** maximum stay, in months, can have decimals */
  maximumStay: number;
  /** maximum number of days when listing booking can start (counting from now), zero means no limit */
  maxBookableDays: number;
  /** defines if listing can be booked (checks availability vs minimum stay, bookable days etc) */
  isBookable: boolean;
  description: string;
  latitude: number;
  longitude: number;
  facilities: ListingFacilities;
  geonameId: number;
  isDeleted: boolean;
  belongsToPartner: boolean;
  hasImmediateRedirect: boolean;
  isNew: boolean;
  isHidden: boolean;
  isInstantAllowed: boolean;
  listingType: string;
  partnerId: number;
  partnerRoomId: string;
  partnerURL: string;
  kind: ListingKind;
  type: ListingType;
  /** @deprecated use `kind` attribute and `ListingKindTranslations` */
  kindLabel: string;
  /** @deprecated use `type` attribute and `ListingTypeTranslations` */
  typeLabel: string;
  alias: string;
  currentOccupancy: number;
  freePlaces: number;
  preferredGender: string;
  minAge: number;
  maxAge: number;
  couplesAllowed: string;
  /** calendar path for the listing. Available only for own listings */
  icsCalendarPath?: string;
  /** not available by default, use `expand=conversationCounters` query param to retrieve */
  gscLdpRatio?: number;
  /** not available by default, use `expand=conversationCounters` query param to retrieve */
  gscCount?: number;
  /** not available by default, use `expand=conversationCounters` query param to retrieve */
  ldpTotalVisits?: number;
  /** not available by default, use `expand=conversationCounters` query param to retrieve */
  ldpVisits?: number;
  /** not available by default, use `expand=exclusions` query param to retrieve */
  exclusions?: ListingExclusion[] | null;
  /** not available by default, use `expand=bookablePeriods` query param to retrieve */
  bookablePeriods?: DateRange[] | null;
  photoURLList?: ListingPhoto[] | null;
  /** not available by default, use `expand=videos` query param to retrieve */
  videoURLList?: ListingVideo[] | null;
  /** tenant has to provide additional data for booking */
  isQualificationNeeded: boolean;
  /** url to external calendar with unavailability of the listing */
  icsUrl: string;
  /** date of last successful download of the ics calendar */
  icsDownloadedAt: string | null;
  /** information if last try of downloading (that's done periodically) was successful or not */
  icsDownloadError: boolean;
  /** not available by default, use `expand=lastMonthConversationCount` query param to retrieve, count of conversation in last 30 days */
  lastMonthConversationCount?: number;
  state: ListingState;
  /** @deprecated use `state` attribute and `ListingStateTranslations` */
  stateLabel: string;
  contractType: ListingContractType;
  cancellationPolicy: ListingCancellationPolicy;
  pricingType: ListingPricingType;
  pricingValues: ListingPricingValues;
  pricingValuesEUR: ListingPricingValues;
  pricingDiscountValues?: Array<PricingDiscountValues>;
  tenantFeeRuleName: string;
  requiredDocuments?: Array<RequiredDocuments>;

  // TODO: not found in swagger.yml. either add there or remove from here.
  advertiserId: number;
  externalReference: string;

  // ? For support ukraine initiative
  isCharitable?: boolean;

  isMultiUnit: boolean;
  unitTypeId: number;
  rentalPeriodType: ListingRentalPeriodType;
  strictRentalPeriods?: ListingStrictRentalPeriod[] | null;
  contracts?: RentalConditionsContract[];
}

export enum ListingKind {
  EntirePlace = 0,
  PrivateRoom = 1,
  SharedRoom = 2,
}

export enum ListingType {
  House = 1,
  Building = 2,
  Apartment = 3,
}

export enum ListingState {
  DRAFT = 0,
  PUBLISHED = 1,
  UNPUBLISHED = 2,
}

export const listingStateLabels = {
  [ListingState.DRAFT]: 'draft',
  [ListingState.PUBLISHED]: 'published',
  [ListingState.UNPUBLISHED]: 'unpublished',
};

export interface ListingFacilities {
  ac: 'yes' | 'no' | null;
  allergy_friendly: 'yes' | 'no' | null;
  animal_allowed: 'yes' | 'no' | 'discussable' | null;
  balcony_terrace: 'no' | 'shared' | 'private' | null;
  basement: 'no' | 'shared' | 'private' | null;
  bathroom:
    | 'no'
    | 'private'
    | 'shared_w_boys'
    | 'shared_w_girls'
    | 'shared_w_both'
    | null;
  bed: 'yes' | 'no' | null;
  bedroom_count: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8+' | null;
  bedroom_furnished: 'yes' | 'no' | null;
  student_housing: 'yes' | 'no' | null;
  co_living: 'yes' | 'no' | null;
  bedroom_size: string | null;
  closet: 'yes' | 'no' | null;
  desk: 'yes' | 'no' | null;
  dishwasher: 'yes' | 'no' | null;
  dryer: 'yes' | 'no' | null;
  flooring:
    | 'laminate'
    | 'carpet'
    | 'stone'
    | 'wood'
    | 'plastic'
    | 'other'
    | null;
  garden: 'no' | 'shared' | 'private' | null;
  heating: 'no' | 'central' | 'gas' | 'wood_stove' | 'electrical' | null;
  housemates_gender:
    | 'shared_w_boys'
    | 'shared_w_girls'
    | 'shared_w_both'
    | null;
  kitchen: 'no' | 'shared' | 'private' | null;
  kitchenware: 'no' | 'shared' | 'private' | null;
  living_room: 'no' | 'shared' | 'private' | null;
  lock: 'yes' | 'no' | null;
  lroom_furniture: 'yes' | 'no' | null;
  parking: 'no' | 'shared' | 'private' | null;
  play_music: 'yes' | 'no' | 'discussable' | null;
  registration_possible: 'yes' | 'no' | null;
  smoking_allowed: 'yes' | 'no' | 'discussable' | 'outside_only' | null;
  tenant_status:
    | 'any'
    | 'working'
    | 'student'
    | 'students only' // @todo recheck
    | 'working student'
    | 'working professionals only'
    | 'looking for a job'
    | null;
  toilet: 'no' | 'shared' | 'private' | null;
  total_size: string | null;
  tv: 'yes' | 'no' | null;
  washing_machine: 'yes' | 'no' | null;
  wheelchair_accessible: 'yes' | 'no' | null;
  wifi: 'yes' | 'no' | null;
}

export interface ListingPreferredTenant {
  minAge: number;
  maxAge: number;
  status:
    | 'any'
    | 'working'
    | 'student'
    | 'students only'
    | 'working student'
    | 'working professionals only'
    | 'looking for a job'
    | null;
  gender: string;
  couplesAllowed: string;
}

export type ListingFacilityKey = keyof ListingFacilities;

export enum ListingExclusionSource {
  /** manually blocked by Advertiser */
  manual = '',
  /** ha bookings */
  booking = 'booking',
  /** external bookings */
  ics = 'ics',
  /** non-bookable period between 2 exclusion periods */
  notBookable = 'non-bookable',
}

export interface ListingExclusion {
  from: string;
  to: string;
  reason: string;
  source: `${ListingExclusionSource}`;
  isPartial: boolean;
  conversationId: number;
}

export type ListingPhotoCategory = //   empty string means the photo is not yet processed for category

    | ''
    // the list can be found at /types/image_defs.go
    | 'unknown'
    | 'kitchen'
    | 'bathroom'
    | 'bedroom'
    | 'livingroom'
    | 'neighborhood'
    | 'floorplan';

export type ListingPhotoType = 'photo' | 'floorplan' | '360';
export enum ListingPhotoTypeValues {
  PHOTO = 'photo',
  PANORAMIC = '360',
  FLOORPLAN = 'floorplan',
}

export interface ListingPhoto {
  id: number;
  url: string;
  category: ListingPhotoCategory;
  type: ListingPhotoType;
}

export interface ListingVideo {
  fileSize: number;
  id: number;
  youtubeId: string;
  name: string;
  vimeoId: string;
  title: string;
  author: string;
  length: number;
  thumbnailURL?: string; // Only available in vimeo videos
}

export enum ListingContractType {
  DAILY = 'daily',
  FORTNIGHTLY = 'fortnightly',
  MONTHLY = 'monthly',
}

export enum ListingCancellationPolicy {
  STRICT = 'strict',
  /** @deprecated use FLEXIBLE_30D instead */
  FLEXIBLE = 'flexible',
  FLEXIBLE_30D = '30d-movein-flexible',
}

export type ListingCostKey =
  | 'security-deposit'
  | 'bedding-deposit'
  | 'towel-deposit'
  | 'other-deposit'
  | 'administration-fee'
  | 'utility-bills'
  | 'water-bill'
  | 'gas-bill'
  | 'electricity-bill'
  | 'internet-bill'
  | 'other-additional-costs'
  | 'final-cleaning'
  | 'cleaning-fee'
  | 'cleaning-service'
  | 'overnight-guests'
  | 'other-optional-costs'
  | 'early-move-in'
  | 'early-move-out'
  | 'late-move-in'
  | 'late-move-out'
  | 'bike-rent'
  | 'full-board'
  | 'half-board'
  | 'gym'
  | 'parking'
  | 'towels-and-bedding'
  | 'broadcasting-bill'
  | 'end-early-fee';

export type ListingCostPayableBy =
  | 'tenant'
  | 'included-in-rent'
  | 'not-applicable'
  | 'unknown';

export enum ListingCostPayableByValues {
  TENANT = 'tenant',
  INCLUDED_IN_RENT = 'included-in-rent',
  NOT_APPLICABLE = 'not-applicable',
  UNKNOWN = 'unknown',
}

export type ListingCostPayableAt =
  | 'move-in'
  | 'move-out'
  | 'one-off'
  | 'monthly'
  | 'bi-monthly'
  | 'bi-weekly'
  | 'weekly';

export enum ListingCostPayableAtValues {
  MOVE_IN = 'move-in',
  MOVE_OUT = 'move-out',
  ONE_OFF = 'one-off',
  MONTHLY = 'monthly',
  BIMONTHLY = 'bi-monthly',
  BIWEEKLY = 'bi-weekly',
  WEEKLY = 'weekly',
}

export type ListingCostGroup =
  | 'optional-costs'
  | 'additional-costs'
  | 'deposit-costs';

export enum ListingPricingType {
  FLAT = 'flat',
  MONTHLY = 'monthly',
}

export enum PricingMonthlyKeys {
  'm-01' = 'm-01',
  'm-02' = 'm-02',
  'm-03' = 'm-03',
  'm-04' = 'm-04',
  'm-05' = 'm-05',
  'm-06' = 'm-06',
  'm-07' = 'm-07',
  'm-08' = 'm-08',
  'm-09' = 'm-09',
  'm-10' = 'm-10',
  'm-11' = 'm-11',
  'm-12' = 'm-12',
}

export type ListingPricingValues = {
  flat: number;
  monthly: { [K in PricingMonthlyKeys]: number } | null;
  precise?: Record<string, number>;
};

export enum ListingCostGroupValues {
  OPTIONAL = 'optional-costs',
  ADDITIONAL = 'additional-costs',
  DEPOSIT = 'deposit-costs',
}

export interface ListingCost {
  /** cost value, in cents. Should be set to 0 when `payableBy != tenant` is set */
  value: number;
  /** who pays that cost? */
  payableBy: ListingCostPayableBy;
  /* when is the payment due? */
  payableAt: ListingCostPayableAt;
  /** is this payment required? */
  required: boolean;
  /** is this cost refundable at the end of stay? */
  refundable: boolean;
  /** is this cost an estimation? */
  isEstimated: boolean;
  calculationRule?: {
    type: 'percent-of-price' | 'fixed';
    value: number;
  };
}

export type ListingCostsCosts = {
  [key in ListingCostKey]?: ListingCost;
};

export type ListingCostsGroups = {
  [key in ListingCostKey]?: ListingCostGroup;
};

export type ListingCosts = {
  costs: ListingCostsCosts;
  groups: ListingCostsGroups;
  requiredPerMonth?: number | null;
  requiredAtMoveIn?: number | null;
  requiredAtMoveOut?: number | null;
  requiredAndRefundable?: number | null;
};

export enum ListingRentalPeriodType {
  STRICT = 'strict',
  FLEXIBLE = 'flexible',
}

export interface ListingStrictRentalPeriod {
  id: number;
  unitTypeId: number;
  startDate: string;
  endDate: string;
  moveInEndDate: string;
  price: number;
  currency: string;
  createdAt: string;
  updatedAt: string;
}

export enum DiscountType {
  LOS_DISCOUNT = 'LengthOfStayPct',
}

export interface LODDiscountTypeData {
  stayFrom: number;
  stayTo?: number | null;
  discountPercentage: number;
}

export interface PricingDiscountValues {
  discountType: DiscountType;
  isActive: boolean;
  discountData: {
    lengthOfStayPct: LODDiscountTypeData[];
  };
}

export interface UpdateListingPayload {
  address?: string;
  structuredAddress?: {
    countryCode: string;
    city: string;
    postalCode: string;
    street: string;
    houseNumber?: string;
    aptNumber?: string;
  };
  kind?: number;
  type?: number;
  description?: string;
  alias?: string;
  currency?: string;
  price?: number | null;
  maximumStay?: number;
  minimumStayMonths?: number;
  maxBookableDays?: number;
  currentOccupancy?: number;
  freePlaces?: number | null;
  minAge?: number;
  maxAge?: number;
  preferredGender?: string;
  isInstantAllowed?: boolean;
  facilities?: ListingFacilities;
  how?: string;
  icsUrl?: string;
  costs?: ListingCostsCosts;
  contracts?: RentalConditionsContract[];
  contractType?: ListingContractType;
  cancellationPolicy?: ListingCancellationPolicy;
  pricingType?: ListingPricingType;
  pricingValues?: ListingPricingValues;
  isCharitable?: boolean;
}

export interface UpdateListingPayloadWithRequirements
  extends UpdateListingPayload {
  requiredDocuments?: RequiredDocumentsFormValues;
}
