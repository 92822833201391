import React from 'react';

import { useSelector } from 'react-redux';

import { AUTH_IMPERSONATE_END_URL } from '@ha/auth/common/routes';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { useIntl } from 'ha/i18n';
import { useServices } from 'ha/services';

import { clearUserData } from '../clearUserData';
import { getImpersonatingAdmin } from '../selectors';

interface ImpersonatingAdmin {
  id: number;
  name: string;
}

interface ChildrenProps {
  impersonatingAdmin: ImpersonatingAdmin | null;
  onStop: () => void;
}

interface Props {
  children: React.FunctionComponent<ChildrenProps>;
}

export const useImpersonationInformation = () => {
  const { urlResolver } = useIntl();
  const { cookie } = useServices();

  const impersonatingAdmin = useSelector(getImpersonatingAdmin);

  const stopImpersonation = React.useCallback(async () => {
    await fetch(AUTH_IMPERSONATE_END_URL).catch(error => {
      reportError(new Error('Failed impersonation stop', { cause: error }));
    });

    clearUserData(cookie);

    window.location.assign(urlResolver.getAdminPanelUrl());
  }, [urlResolver, cookie]);

  return {
    impersonatingAdmin,
    onStop: stopImpersonation,
  };
};

export function Impersonation({ children }: Props) {
  const impersonationInformation = useImpersonationInformation();

  return children(impersonationInformation);
}
