import qs from 'query-string';

interface CommonProps {
  redirectPath: string;
  [key: string]: string | undefined;
}

const prepareQueryParams = ({ redirectPath, ...queryParams }: CommonProps) => {
  return qs.stringify(
    {
      redirectPath,
      ...queryParams,
    },
    {
      arrayFormat: 'comma',
      skipNull: true,
    },
  );
};

class MobileUrlResolver {
  private baseUrl: string;

  constructor() {
    this.baseUrl = 'com.housinganywhere.app';
  }

  geBaseUrl() {
    return this.baseUrl;
  }

  /**
   * @deprecated Use more specific url methods available on the `mobileUrlResolver` instead
   */
  getProtectedWebViewUrl({
    redirectPath = '/',
    ...rawQueryParams
  }: CommonProps) {
    const queryParams = prepareQueryParams({ redirectPath, ...rawQueryParams });
    return `${this.baseUrl}://ha-webview?${queryParams}`;
  }

  getTenantMessagesUrl({ redirectPath = '/', ...rawQueryParams }: CommonProps) {
    const queryParams = prepareQueryParams({ redirectPath, ...rawQueryParams });
    return `${this.baseUrl}://(tenant)/messages?${queryParams}`;
  }

  getFullAppPathUrl(
    tabPath: string,
    { redirectPath = '/', ...rawQueryParams }: CommonProps,
  ) {
    const queryParams = prepareQueryParams({ redirectPath, ...rawQueryParams });
    return `${this.baseUrl}://${tabPath}?${queryParams}`;
  }
}

export const mobileUrlResolver = new MobileUrlResolver();
