import { ActionsUnion, createAction } from '@housinganywhere/safe-redux';

import type { SubscriptionUserType } from '@ha/api/v2/getSubscriptionProductsByHandle';
import { SubscriptionProduct } from '@ha/api/v2/types/Subscriptions';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { Thunk } from 'ha/myredux/types';
import { GlobalState } from 'ha/types/store';

export enum ActionTypes {
  START = '[SubscriptionLanding] LOAD_PRODUCT',
  DONE = '[SubscriptionLanding] LOAD_PRODUCT_DONE',
  FAIL = '[SubscriptionLanding] LOAD_PRODUCT_FAIL',
}

export const Actions = {
  start: (productType: SubscriptionUserType) =>
    createAction(ActionTypes.START, productType),
  done: (product: SubscriptionProduct) =>
    createAction(ActionTypes.DONE, product),
  fail: () => createAction(ActionTypes.FAIL),
};

export type ActionsType = ActionsUnion<typeof Actions>;

type LoadProduct = (productType: SubscriptionUserType) => Thunk<GlobalState>;
const loadProduct: LoadProduct =
  productType =>
  async (dispatch, _, { apiV2 }) => {
    dispatch(Actions.start(productType));

    return apiV2
      .getSubscriptionProductsByHandle(productType)
      .then(res => {
        dispatch(Actions.done(res.data));
      })
      .catch(err => {
        reportError(new Error('request loadProduct failed', { cause: err }));
        return dispatch(Actions.fail());
      });
  };

export { loadProduct };
