import React from 'react';

import { Typography } from '@hbf/dsl/core';

import { useHAMobileApp } from './MobileAppProvider';
import { getAppVersion } from './utils';

export const HAAppMetadata = () => {
  const { appVersion, appBuild } = useHAMobileApp();

  if (!appVersion) return null;

  return (
    <Typography
      textAlign="center"
      variant="caption/regular"
      data-ha-mobile="app-metadata"
    >
      {getAppVersion({ appBuild: appBuild ?? '', appVersion })}
    </Typography>
  );
};
