import {
  Locale,
  createTranslate,
  createNumberFormat,
  createDateTimeFormat,
} from '@ha/intl';

import { UrlResolver } from 'ha/helpers/UrlResolver';

import { IntlConfig, IntlObject } from './types';

export function createIntl({
  lang,
  baseDomain,
  adminPanelUrl,
  rmsBaseDomain,
  messages,
}: IntlConfig): IntlObject {
  const locale = Locale[lang];

  return {
    lang,
    locale,
    messages,
    ...createTranslate(messages),
    ...createNumberFormat(locale),
    ...createDateTimeFormat(locale),
    urlResolver: UrlResolver.create({
      baseDomain,
      adminPanelUrl,
      rmsBaseDomain,
      lang,
    }),
  };
}
