import { RegistrationValue } from '../../../types/SearchFilters';
import { FilterBuilder } from '../Types';

const registrationBuilder: FilterBuilder = ({ registration = [] }) => {
  if (
    registration.length === 0 ||
    (registration.includes(RegistrationValue.POSSIBLE) &&
      registration.includes(RegistrationValue.NOT_POSSIBLE))
  ) {
    return '';
  }

  return registration[0] === 'not-possible'
    ? 'facility_registration_possible:no'
    : 'facility_registration_possible:yes';
};

export { registrationBuilder };
