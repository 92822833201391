import { MakeCall } from './createMakeCall';

export enum ManagedGroupUserStatus {
  SEARCHING = 'searching',
  BOOKING_CONFIRMED = 'booking-confirmed',
  PAYMENT_DUE = 'payment-due',
  AWAITING_CONFIRMATION = 'awaiting-confirmation',
  MOVED_IN = 'moved-in',
}

export interface ManagedGroupUser {
  id: number;
  uuid: string;
  firstName: string;
  lastName: string;
  avatarURL: string;
  status: ManagedGroupUserStatus;
  unreadCount: number;
  countryCode: string;
  email: string;
}

export interface ManagedGroupUsersResponse {
  counts: number;
  users: ManagedGroupUser[];
}

export interface GetManagedGroupUsersQuery {
  limit?: number;
  offset?: number;
}

const getManagedGroupUsers =
  (makeCall: MakeCall) =>
  (groupUUID: string, query: GetManagedGroupUsersQuery) =>
    makeCall<ManagedGroupUsersResponse>(
      'GET',
      `/api/v2/managed-groups/${groupUUID}/users`,
      { query },
    );

export { getManagedGroupUsers };
