import { createAction } from 'redux-actions';

import { Thunk } from 'ha/myredux/types';

import { Actions } from '../constants';
import { AlertType } from '../types';

export const sendAlert = createAction(Actions.SEND);
export const sendPersistentAlert = createAction(Actions.SEND_PERSISTENT);
export const sendAll = createAction(Actions.SEND_ALL);
export const dismiss = createAction(Actions.DISMISS);
export const clear = createAction(Actions.CLEAR);

export const send =
  (data: {
    id?: string | number;
    text: React.ReactNode;
    kind?: AlertType | string;
    dismissAfter?: number;
    persistent?: boolean;
  }): Thunk<unknown> =>
  dispatch => {
    if (!data.id) {
      // eslint-disable-next-line no-param-reassign
      data.id = new Date().getTime();
    }

    // @ts-expect-error - TSMigration
    dispatch(sendAlert(data));
  };
