export enum Environment {
  PRODUCTION = 'production',
  STAGE = 'stage',
  STAGE2 = 'stage2',
  STAGE3 = 'stage3',
  STAGE4 = 'stage4',
  DEVELOPMENT = 'development',
  TEST = 'test',
}

export interface EnvironmentVariables {
  DEBUG: boolean;
  NODE_ENV: string;
  WEB_APP_ENABLE_SW: boolean;
  ANALYTICS_ENABLED: boolean;
  ALLOW_INDEXING: boolean;
  PLATFORM_API_PUBLIC: string;
  HERMES_PUBLIC_URL: string;
  ALMANAC_PUBLIC_URL: string;
  BELL_PUBLIC_URL: string;
  DOMAIN: string;
  ADMIN_PANEL_URL: string;
  CDN_DOMAIN: string;
  STRIPE_API_TOKEN: string;
  FACEBOOK_CLIENT_ID: string;
  FACEBOOK_APP_ID: string;
  FACEBOOK_DOMAIN_VERIFICATION: string;
  GOOGLE_CLIENT_ID: string;
  ADYEN_HOSTNAME: string;
  GOOGLE_MAPS_KEY: string;
  GOOGLE_MAP_ID: string;
  GOOGLE_TRANSLATE_TOKEN: string;
  LANGUAGE_DETECTION_URL: string;
  RUDDERSTACK_WRITE_KEY: string;
  RUDDERSTACK_DATA_PLANE_URL: string;
  RUDDERSTACK_CDN: string;
  RUDDERSTACK_SOURCE_CONFIG: string;
  ALGOLIA_APP_ID: string;
  ALGOLIA_INDEX_NAME: string;
  ALGOLIA_INDEX_NAME_NEXT: string;
  ALGOLIA_INDEX_DISTINCT_UNIT_TYPE_ID: string;
  ALGOLIA_INDEX_LOW_TO_HIGH: string;
  ALGOLIA_INDEX_HIGH_TO_LOW: string;
  ALGOLIA_INDEX_EXPERIMENT: string;
  ALGOLIA_INDEX_ADVERTISER_RATING: string;
  ALGOLIA_INDEX_MOST_RECENT: string;
  ALGOLIA_INDEX_CHARITABLE: string;
  ALGOLIA_API_KEY: string;
  IMMOBILIARE_MAPS_KEY: string;
  GOOGLE_TRACKING_ID: string;
  OSIRIS_URL: string;
  OSIRIS_PUBLIC_URL: string;
  METIS_URL: string;
  HA_THUMB_IMAGE_TIMEOUT_MS: number;
  MEDIA_QUERY_NEXT: boolean;
  RECAPTCHA_V2_INVISIBLE_KEY: string;
  // The URL of the Imgix source for this environment
  // https://dashboard.imgix.com/sources
  IMGIX_SOURCE_URL: string;
  CHEQ_ENABLED: boolean;
  STUDAPART_ADVERTISER_ID?: number;
  AVUXI_KEY: string;
  // Support for RMS <-> Tokamak redirects
  RMS_BASE_DOMAIN: string;
  // Cookies banner provider
  ONETRUST_DOMAIN_KEY: string;
  // Customer Support chat
  INTERCOM_APP_ID: string;
  // Sentry DSN
  SENTRY_DSN_REACT: string;
  SENTRY_DSN_NODE: string;
  SENTRY_ENABLED: boolean;
  SENTRY_DEBUG: boolean;
  SENTRY_SAMPLE_RATE: number;
  SENTRY_SAMPLE_COMMON_ERRORS_RATE: number;
  SENTRY_NORMALIZE_DEPTH: number;
  // Enables tracking analytics from China
  BAIDU_ENABLED: boolean;
  BAIDU_TAG_ID: string;
  // Hubspot IDs
  HUBSPOT_PORTAL_ID: string;
  HUBSPOT_INTEGRATIONS_FORM_ID: string;
  HUBSPOT_SUPPLY_LANDING_FORM_ID: string;
  // UNLESS UI testing script
  UNLESS_ENABLED: boolean;
  // Maze UI testing script
  MAZE_ENABLED: boolean;

  // IDP URL
  AUTH_ISSUER_URL: string;

  TRACE_CLIENT_ENABLED: boolean;
  TRACE_CLIENT_COLLECTOR_URL_PUBLIC: string;
  TRACE_SERVICE_NAMESPACE: string;
  TRACE_SERVICE_VERSION: string;
}
