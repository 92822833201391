import { ExclusivityValue } from '../../../types/SearchFilters';
import { FilterBuilder } from '../Types';

export const exclusivityBuilder: FilterBuilder = ({ exclusivity }, context) => {
  const uniId = context?.uniId;
  if (!uniId || exclusivity === ExclusivityValue.NON_EXCLUSIVE) {
    // show all public listings
    return 'exclusivityPartnerIDs:0';
  }

  if (exclusivity === ExclusivityValue.EXCLUSIVE) {
    // show only exclusive listings
    return `exclusivityPartnerIDs:${uniId}`;
  }

  // show both public and exclusive listings
  return `(exclusivityPartnerIDs:${uniId} OR exclusivityPartnerIDs:0)`;
};
