import { MakeCall } from '../createMakeCall';
import { PropertyVideo } from '../types/inventory/Property';

const addPropertyYoutubeVideo =
  (makeCall: MakeCall) => (propertyId: number, youtubeUrl: string) =>
    makeCall<PropertyVideo[]>(
      'POST',
      `/api/v2/property/${propertyId}/youtube-video`,
      {
        data: { youtubeUrl },
      },
    );
export { addPropertyYoutubeVideo };
