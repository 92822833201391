import { HAMobileAppMetadata } from './types';

declare global {
  interface Window {
    haMobileAppMetadata: import('./types').HAMobileAppMetadata | undefined;
    isHaMobileApp: boolean | undefined;
    isTrackingPermissionGranted: boolean | undefined;
    isPNPermissionGranted: boolean | undefined;
    currentTabPath: string;
    OneTrust: {
      AllowAll: () => void;
      RejectAll: () => void;
    };
    ReactNativeWebView: {
      postMessage: (message: string) => void;
    };
  }
}

export const isHaMobileApp = (): boolean => {
  try {
    return Boolean(typeof window !== 'undefined' && window.isHaMobileApp);
  } catch {
    return false;
  }
};

export const isPNPermissionGranted = (): boolean => {
  if (!isHaMobileApp()) return false;
  return Boolean(window.isPNPermissionGranted);
};

export const getHaMobileAppMetadata = () => {
  if (!isHaMobileApp()) return undefined;
  if (!window.haMobileAppMetadata) return undefined;

  return window.haMobileAppMetadata;
};

export const getAppVersion = (
  metadata: Pick<HAMobileAppMetadata, 'appVersion' | 'appBuild'>,
): string | null => {
  if (metadata?.appVersion) {
    return `v${metadata.appVersion}${
      metadata.appBuild ? ` (${metadata.appBuild})` : ''
    }`;
  }
  return null;
};

export const isVersionGreaterOrEqual = (
  args: {
    compareVersion?: string;
    currentVersion?: string;
  } = { compareVersion: '', currentVersion: '' },
): boolean => {
  try {
    if (!args.compareVersion || !args.currentVersion) {
      return false;
    }

    const currentVersion = args.currentVersion.split('.').map(Number);
    const compareVersion = args.compareVersion.split('.').map(Number);

    for (
      let i = 0;
      i < Math.max(currentVersion.length, compareVersion.length);
      i += 1
    ) {
      const current = currentVersion[i] || 0;
      const compare = compareVersion[i] || 0;
      if (current !== compare) {
        return current > compare;
      }
    }
    return true;
  } catch {
    return false;
  }
};

export const isTrackingPermissionGranted = (): boolean => {
  if (!isHaMobileApp()) return false;
  return window.isTrackingPermissionGranted || false;
};

const CHECK_INTERVAL = 500; // ms
const TIMEOUT = 5000; // ms
const waitForOneTrust = () => {
  return new Promise<typeof window.OneTrust>((resolve, reject) => {
    let timeoutId: NodeJS.Timeout;

    const intervalId = setInterval(() => {
      if (window.OneTrust) {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
        resolve(window.OneTrust);
      }
    }, CHECK_INTERVAL);

    timeoutId = setTimeout(() => {
      clearInterval(intervalId);
      reject(new Error('OneTrust not found within timeout'));
    }, TIMEOUT); // Adjust the timeout as needed
  });
};

export const consentToCookiePopUpFromHaMobileApp = async () => {
  try {
    await waitForOneTrust();
    if (isTrackingPermissionGranted()) {
      window.OneTrust.AllowAll();
      return;
    }
    window.OneTrust.RejectAll();
  } catch {
    // do nothing
  }
};

export const shouldOpenQuickActionsDrawer = (): boolean => {
  try {
    const openQuickActionsDrawer = window.location.search.includes(
      'openQuickActionsDrawer=true',
    );
    return openQuickActionsDrawer;
  } catch {
    return false;
  }
};

export const getCurrentTabPath = (): string => {
  if (!isHaMobileApp()) return '';
  return window.currentTabPath || 'index';
};
