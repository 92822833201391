export interface PriceFilterValue {
  currency: string;
  min: number | null;
  max: number | null;
}

export enum TypeValue {
  PRIVATE_ROOM = 'private-rooms',
  SHARED_ROOM = 'shared-rooms',
  APARTMENT = 'apartment-for-rent',
  STUDIO = 'studio-for-rent',
  STUDENT_HOUSING = 'student-housing',
}
export type TypeFilterValue = TypeValue[];
export type DateString = string | null;

export interface DateFilterValue {
  startDate: DateString;
  endDate: DateString;
  flexDays?: number;
}

export enum AdvertiserRatingValue {
  OVER_FOUR = '4+',
  OVER_THREE = '3+',
  UNRATED = 'unrated',
}

export type AdvertiserRatingFilterValue = AdvertiserRatingValue;
export type AdvertiserRatingFilterCount = {
  overFour?: number;
  overThree?: number;
  unrated?: number;
};
export enum FurnitureValue {
  FURNISHED = 'furnished',
  UNFURNISHED = 'unfurnished',
}

export type FurnitureFilterValue = FurnitureValue[];

export enum BillsValue {
  INCLUDED = 'included',
  EXCLUDED = 'excluded',
}
export type BillsFilterValue = BillsValue[];

export enum GenderValue {
  GIRLS = 'female',
  BOYS = 'male',
}

export type GenderFilterValue = GenderValue[];

export enum RegistrationValue {
  POSSIBLE = 'possible',
  NOT_POSSIBLE = 'not-possible',
}
export type RegistrationFilterValue = RegistrationValue[];

export enum Options {
  NO = 'no',
  YES = 'yes',
  DISCUSSABLE = 'discussable',
  OUTSIDE = 'outside_only',
  PRIVATE = 'private',
  SHARED = 'shared',
}

export enum HeatingOptions {
  CENTRAL = 'central',
  GAS = 'gas',
  WOOD_STOVE = 'wood_stove',
  ELECTRICAL = 'electrical',
  NO = 'no',
}

export enum RulesValue {
  SMOKING = 'smoking_allowed',
  PETS = 'pets_allowed',
  INSTRUMENT = 'playing_instrument_allowed',
}

export type RulesFilterValue = RulesValue[];

export enum RoomsValue {
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR_AND_MORE = '4+',
}
export interface RoomsFilterValue {
  bedroomCount: RoomsValue[];
}

export type RecentlyAddedValue =
  | 'all'
  | '1-day'
  | '3-days'
  | '7-days'
  | '14-days';
export type RecentlyAddedFilterValue = RecentlyAddedValue;

export enum SuitableForValue {
  STUDENTS = 'students',
  WORKING_PROFESSIONALS = 'working-professionals',
  COUPLES = 'couples',
}
export type SuitableForFilterValue = SuitableForValue[];

export enum SortingValue {
  BestMatch = 'bestMatch',
  MostRecent = 'mostRecent',
  LowToHigh = 'lowToHigh',
  HighToLow = 'highToLow',
  AdvertiserRating = 'advertiserRating',
}

export enum ContractTypeValue {
  Daily = 'daily',
  Fortnightly = 'fortnightly',
  Monthly = 'monthly',
  Unknown = 'unknown',
}
export type ContractTypeFilterValue = ContractTypeValue[];

export enum FacilitiesValue {
  PRIVATE_BATHROOM = 'private_bathroom',
  BALCONY_TERRACE = 'balcony_terrace',
  GARDEN = 'garden',
  KITCHEN = 'kitchen',
  PET_FRIENDLY = 'pets_friendly',
  PARKING = 'parking',
  WHEELCHAIR_ACCESSIBLE = 'wheelchair_accessible',
  BASEMENT = 'basement',
  HEATING = 'heating',
  BED = 'bed',
  STUDENT_HOUSING = 'student_housing',
}
export type FacilitiesFilterValue = FacilitiesValue[];

export enum AmenitiesValue {
  TV = 'tv',
  DESK = 'desk',
  DISHWASHER = 'dishwasher',
  WASHING_MACHINE = 'washing_machine',
  DRYER = 'dryer',
  AIR_CONDITIONING = 'ac',
  HEATING = 'heating',
}
export type AmenitiesFilterValue = AmenitiesValue[];

export enum FreePlacesValue {
  ZERO = 'f0',
  ONE = 'f1',
  TWO = 'f2',
  THREE = 'f3',
  FOUR = 'f4',
  FIVE_AND_MORE = 'f5+',
}
export type FreePlacesFilterValue = FreePlacesValue | null;

export enum MaximumStayValue {
  ZERO = 'm0',
  W1 = 'w1',
  W2 = 'w2',
  W3 = 'w3',
  M1 = 'm1',
  M2 = 'm2',
  M3 = 'm3',
  M4 = 'm4',
  M5 = 'm5',
  M6 = 'm6',
}
export type MaximumStayFilterValue = MaximumStayValue | null;

export enum ExclusivityValue {
  EXCLUSIVE = 'exclusive',
  NON_EXCLUSIVE = 'non-exclusive',
  ALL = 'all',
}
export type ExclusivityFilterValue = ExclusivityValue | null;

export type SortingOption = Array<{ key: string; value: SortingValue }>;
