/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/react';

import { getCurrentSpan } from '../tracing/trace';

type Teams = 'payments';
type Type = 'data-fetching-server-error' | 'data-fetching-unexpected-error';

export interface Options {
  /** @deprecated This data is not searchable - consider using tags or chaining errors with `new Error("...", { cause: original })`  */
  metaData?: Record<string, unknown>;
  severity?: 'info' | 'warning' | 'error';
  tags?: Record<string, string | number | boolean> & {
    team?: Teams;
    type?: Type;
  };
}

export function reportError(error: Error, options?: Options) {
  const span = getCurrentSpan();

  if (span) {
    Sentry.setContext('trace', {
      traceId: span?.spanContext().traceId,
    });
  }

  try {
    Sentry.captureException(error, {
      tags: options?.tags,
      extra: options?.metaData,
      level: options?.severity || 'error',
    });

    if (process.browser) {
      // eslint-disable-next-line no-console
      console.error('The following error was manually captured', error);
    }
  } catch (anotherError) {
    // just a precaution - can be removed in case we don't see any errors appear
    Sentry.captureException(
      new Error('Failed to capture error', { cause: anotherError }),
      {
        extra: { original: error, ...options?.metaData },
        level: options?.severity || 'error',
      },
    );
  }
}
