import { TouchPoint } from '@ha/api/v2/types/Affiliates';

import { getUtmSource } from '../../Analytics/helpers/getUtmSource';

import { DEFAULT_PROVIDER } from './constants';
import { session } from './session/session';
import { touchPoints } from './touchPoints';
import { trackAffiliate } from './trackAffiliate';

export const trackTouchPoint = async (url: URL) => {
  const touchPointsList = await touchPoints.get();

  if (!url || !session.isEnded()) {
    return touchPointsList;
  }

  const referringDomain = document.referrer;
  const source = getUtmSource(url, referringDomain);
  const tags = Object.fromEntries(new URLSearchParams(url.search));

  let provider = DEFAULT_PROVIDER;
  const touchPoint: TouchPoint = {
    tags,
    source,
    referringDomain,
    clickId: '',
    time: new Date().toISOString(),
  };

  const affiliate = trackAffiliate(url);
  if (affiliate) {
    provider = affiliate.provider;
    touchPoint.clickId = affiliate.clickId;
  }

  if (provider in touchPointsList) {
    touchPointsList[provider]?.push(touchPoint);
  } else {
    touchPointsList[provider] = [touchPoint];
  }

  touchPoints.set(touchPointsList);

  return touchPointsList;
};
