import { Language } from './types';

export function getLanguageFromHeaders(
  headers: Record<string, unknown>,
): Language | undefined {
  // Sample - nl-NL,en-US;q=0.9,en;q=0.8
  const acceptLanguage = (headers?.['accept-language'] ||
    headers?.['Accept-Language']) as string | undefined;

  if (!acceptLanguage) return undefined;

  // Use regex to match language codes and extract first part
  const languages = acceptLanguage
    .split(',') // ['nl-NL', 'en-US;q=0.9', 'en;q=0.8']
    .map(lang => lang.substring(0, 2).toLowerCase().trim()); // ['nl', 'en', 'en']

  for (const lang of languages) {
    if (lang && lang in Language) {
      return Language[lang as keyof typeof Language];
    }
  }

  return undefined;
}

const langPathRegex = new RegExp(
  `^/(?<lang>(?:${Object.keys(Language).join('|')}))(?:$|(?:/.*))`,
);

export function getLanguageFromURL(
  urlORPathname: string,
): Language | undefined {
  const { pathname } = new URL(urlORPathname, 'http://localhost'); // second argument here is to handle pathname only without a failure
  const { lang = '' } = langPathRegex.exec(pathname)?.groups || {};

  if (lang in Language) {
    return lang as Language;
  }

  return undefined;
}

export function getLanguage({
  urlOrPathname,
  headers,
}: {
  urlOrPathname?: string;
  headers?: Record<string, unknown>;
}): Language {
  let lang: Language | undefined;

  if (urlOrPathname) {
    lang = getLanguageFromURL(urlOrPathname);
  }

  if (!lang && headers) {
    lang = getLanguageFromHeaders(headers);
  }

  return lang || Language.en;
}
