import { AnyAction } from 'redux';

import {
  Actions,
  ConnectionState,
} from 'ha/modules/RealtimeMessaging/constants';

export const isConnectionUpdatedAction = (action: AnyAction) =>
  action.type === Actions.REALTIME_CONNECTION_UPDATED &&
  action.payload === ConnectionState.connected;
