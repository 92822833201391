export interface PrivateLandlordLandingLoaderData {
  userCountry: UserCountries;
}

export interface PricingPlanFeature {
  label: string;
  list: {
    id: number;
    label: string;
    subtitle?: {
      label: string;
    };
  }[];
}

export interface PrivateLandlordLandingState {
  userCountry: UserCountries;
  isCountrySelectedByUser: boolean;
}

export enum UserCountries {
  AT = 'AT', // Austria
  BE = 'BE', // Belgium
  CA = 'CA', // Canada
  CZ = 'CZ', // Czech Republic
  FR = 'FR', // France
  DE = 'DE', // Germany
  GR = 'GR', // Greece
  HU = 'HU', // Hungary
  IS = 'IS', // Iceland
  IE = 'IE', // Ireland
  IT = 'IT', // Italy
  NL = 'NL', // Netherlands
  PL = 'PL', // Poland
  PT = 'PT', // Portugal
  ES = 'ES', // Spain
  SE = 'SE', // Sweden
  GB = 'GB', // United Kingdom
  US = 'US', // United States
  CH = 'CH', // Switzerland
}

export type UserCountriesCode = keyof typeof UserCountries;

export interface PrivateLandlordCountryInfo {
  flag: string;
  // The name of the country in adjective form.
  label: string;
}
