import dayjs from 'dayjs';

import { dateToTimestamp, getToday, addMonths, addDays } from '@ha/date';

import { DateString } from '../../../types/SearchFilters';
import { FilterBuilder } from '../Types';

const flexDaysExpanded = 14;

type RelationalOperator = 'greater' | 'less';
const dateFromFilter = (
  startDate: string,
  operator: RelationalOperator = 'less',
) => {
  const startDateTS = dateToTimestamp(new Date(startDate));

  if (operator === 'greater') {
    return `dateFromTS>=${startDateTS}`;
  }
  return `dateFromTS<=${startDateTS}`;
};

const dateToFilter = (startDate: string, endDate?: DateString) => {
  // if end date is specified then use that, otherwise use the startDate
  const endDateTS = dateToTimestamp(new Date(endDate || startDate));

  return `dateToTS>=${endDateTS}`;
};

const bookableDateFromFilter = (startDate: string, flexDays = 0) => {
  if (flexDays) {
    const flexDateFromStart = addDays(startDate, flexDays);
    const flexDateFromEnd = dateToTimestamp(
      new Date(addDays(startDate, -flexDays)),
    );

    return `${bookableDateFromStartFilter(
      flexDateFromStart,
    )} AND bookableDateFromEndTS>=${flexDateFromEnd}`;
  }

  const ts = dateToTimestamp(new Date(startDate));
  return `${bookableDateFromStartFilter(
    startDate,
  )} AND bookableDateFromEndTS>=${ts}`;
};

const bookableDateToFilter = (endDate: string, flexDays = 0) => {
  if (flexDays) {
    const flexDateToStartTS = dateToTimestamp(
      new Date(addDays(endDate, flexDays)),
    );
    const flexDateToEndTS = dateToTimestamp(
      new Date(addDays(endDate, -flexDays)),
    );

    return `bookableDateToStartTS<=${flexDateToStartTS} AND bookableDateToEndTS>=${flexDateToEndTS}`;
  }

  const ts = dateToTimestamp(new Date(endDate));
  return `bookableDateToStartTS<=${ts} AND bookableDateToEndTS>=${ts}`;
};

const bookableDateFromStartFilter = (
  startDate: string,
  operator: RelationalOperator = 'less',
) => {
  const ts = dateToTimestamp(new Date(startDate));
  if (operator === 'greater') {
    return `bookableDateFromStartTS>=${ts}`;
  }

  return `bookableDateFromStartTS<=${ts}`;
};

const dateTSBuilder: FilterBuilder = (
  { startDate, endDate, flexDays },
  context,
) => {
  const dateBuilder = context?.withSrpDates
    ? dateBuilderV2({ startDate, endDate, flexDays }, context)
    : dateBuilderV1({ startDate, endDate }, context);
  return dateBuilder;
};

const dateBuilderV1: FilterBuilder = ({ startDate, endDate }) => {
  const today = getToday();

  // when no dates are specified then return all the inventory that has
  // availability in the next 6 months.
  if (!startDate) {
    const todayPlusSixMonths = addMonths(today, 6);

    return dateFromFilter(todayPlusSixMonths);
  }

  const dateFrom = dateFromFilter(startDate);
  const dateTo = dateToFilter(startDate, endDate);

  const dateBuilder = [dateFrom];
  if (dateTo) dateBuilder.push(dateTo);

  // https://housinganywhere.atlassian.net/browse/TX-1663
  const monthDiff = dayjs(startDate).diff(today, 'month', true);
  if (monthDiff < 6 && monthDiff > 1) {
    const dateFromBound = addMonths(startDate, -1);
    const dateFromStartBound = dateFromFilter(dateFromBound, 'greater');

    dateBuilder.push(dateFromStartBound);
  }
  return dateBuilder.join(' AND ');
};

const dateBuilderV2: FilterBuilder = (
  { startDate, endDate, flexDays: flexDaysFilterValue },
  context,
) => {
  const today = getToday();
  const withRelevantAvailability = context?.withRelevantAvailability;

  // when no dates are specified then return all the inventory that has
  // availability in the next 6 months.
  if (!startDate) {
    const todayPlusSixMonths = addMonths(today, 6);

    return bookableDateFromStartFilter(todayPlusSixMonths);
  }

  let flexDays = flexDaysFilterValue;
  if (context?.expanded && (!flexDays || flexDays < flexDaysExpanded)) {
    flexDays = flexDaysExpanded;
  }

  const dateBuilder = [bookableDateFromFilter(startDate, flexDays || 0)];

  if (endDate) {
    const dateTo = bookableDateToFilter(endDate, flexDays || 0);
    dateBuilder.push(dateTo);
  }

  // https://housinganywhere.atlassian.net/browse/TX-1663
  const monthDiff = dayjs(startDate).diff(today, 'month', true);
  if (withRelevantAvailability && monthDiff < 6 && monthDiff > 1) {
    const dateFromBound = addMonths(startDate, -1);
    const dateFromStartBound = bookableDateFromStartFilter(
      dateFromBound,
      'greater',
    );

    dateBuilder.push(dateFromStartBound);
  }

  return dateBuilder.join(' AND ');
};

export { dateTSBuilder };
