export enum OSType {
  IOS = 'ios',
  ANDROID = 'android',
  WINDOWS = 'windows',
  MACOS = 'macos',
  WEB = 'web',
  UNKNOWN = 'unknown',
}

export type HAMobileAppMetadata = {
  /**
   * @example
   * Android: "google", "xiaomi";
   * iOS: "Apple";
   * */
  brand: string;

  /**
   * @example
   * Android: "Google", "xiaomi";
   * iOS: "Apple";
   * */
  manufacturer: string;

  /** @example
   * Android: "Pixel 2";
   * iOS: "iPhone XS Max";
   * */
  modelName: string;

  osType: OSType;

  /**
   * @example
   * Google: "Android";
   * Apple: "iOS" or "iPadOS";
   * Xiaomi: Xiaomi/xxx/xxx
   * Samsung: samsung/xxx/xxx
   * */
  osName: string;

  /** @example
   * Android: "4.0.3";
   * iOS: "12.3.1";
   * */
  osVersion: string;

  /** @example "2.11.0" */
  appVersion: string;

  /** @example
   * Android: "114"
   * iOS: "2.11.0"
   * */
  appBuild: string;
};
