import { MakeCall } from '../createMakeCall';
import { PropertyPhoto } from '../types/inventory/Property';

const confirmPropertyPhotoUpload =
  (makeCall: MakeCall) => (propertyId: number, photoId: number) =>
    makeCall<PropertyPhoto[]>(
      'PUT',
      `/api/v2/property/${propertyId}/photo/${photoId}/confirm`,
    );

export { confirmPropertyPhotoUpload };
