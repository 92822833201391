import { CookieConsentClient, CookieConsentParams } from '@hbf/cookie-consent';

import { reportError } from 'ha/helpers/bugReporter/reportError';

import { CREATION_ERROR } from './constants';

export const createConsentClient = ({
  domainKey,
  withGoogleConsent,
}: CookieConsentParams['options']) => {
  try {
    const client = new CookieConsentClient({
      options: { domainKey, withGoogleConsent },
    });

    return { client };
  } catch (error) {
    reportError(new Error(CREATION_ERROR, { cause: error }));
    return {};
  }
};
