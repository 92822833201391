import { MakeCall } from '../createMakeCall';

const deletePropertyPhoto =
  (makeCall: MakeCall) => (propertyId: number, photoId: number) =>
    makeCall<undefined>(
      'DELETE',
      `/api/v2/property/${propertyId}/photo/${photoId}`,
    );

export { deletePropertyPhoto };
