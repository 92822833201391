/* eslint-disable import/order */
import { unsubscribeFromSurvey } from '@ha/api/v2/surveyReviews/unsubscribeFromSurvey';
import { acceptOrDeclineBookingModificationRequest } from './acceptOrDeclineBookingModificationRequest';
import { acceptTenant } from './acceptTenant';
import { activateUser } from './activateUser';
import { addPayoutMethod } from './addPayoutMethod';
import { addPoiDraft } from './addPoiDraft';
import { addReviewReportObjection } from './addReviewReportObjection';
import { addReviewSurveyReport } from './addReviewSurveyReport';
import { addSavedSearch } from './addSavedSearch';
import { cancelConversation } from './cancelConversation';
import { cancelSubscription } from './cancelSubscription';
import { changeConversationDate } from './changeConversationDate';
import { chargeTenant } from './chargeTenant';
import { confirmPayoutMethod } from './confirmPayoutMethod';
import { confirmUser } from './confirmUser';
import { confirmUserDocumentUpload } from './confirmUserDocumentUpload';
import { createBookingRequest } from './createBookingRequest';
import { createConversation } from './createConversation';
import { createMakeCall } from './createMakeCall';
import { createPaymentIntent } from './createPaymentIntent';
import { createPropertyRegistrationInfo } from './createPropertyRegistrationInfo';
import { createProspectiveLead } from './createProspectiveLead';
import { createQuickReply } from './createQuickReply';
import { createSubscription } from './createSubscription';
import { createTaxPayer } from './createTaxPayer';
import { createTaxPayerTags } from './createTaxPayerTags';
import { declineConversation } from './declineConversation';
import { deleteConversationAttachment } from './deleteConversationAttachment';
import { deleteKycDocument } from './deleteKycDocument';
import { deleteListingExclusionReminder } from './deleteListingExclusionReminder';
import { deletePaymentMethod } from './deletePaymentMethod';
import { deleteQuickReply } from './deleteQuickReply';
import { deleteUser } from './deleteUser';
import { deleteUserDocument } from './deleteUserDocument';
import { editUserDocument } from './editUserDocument';
import { finalizeBooking } from './finalizeBooking';
import { getAverageRent } from './getAverageRent';
import { getBillingInvoiceURL } from './getBillingInvoiceURL';
import { getChangeRequestByConversationId } from './getChangeRequestByConversationId';
import { getCities } from './getCities';
import { getCitiesNearby } from './getCitiesNearby';
import { getCommissionPlans } from './getCommissionPlans';
import { getConversation } from './getConversation';
import { getConversationByToken } from './getConversationByToken';
import { getConversationCounters } from './getConversationCounters';
import { getConversationMessages } from './getConversationMessages';
import { getConversationPayoutInfo } from './getConversationPayoutInfo';
import { getConversations } from './getConversations';
import { getCurrencyEuroRates } from './getCurrencyEuroRates';
import { getEngagements } from './getEngagements';
import { getFeaturedCities } from './getFeaturedCities';
import { getFullUrl } from './getFullUrl';
import { createIdentityVerificationRedirectURL } from './createIdentityVerificationRedirectURL';
import { getIdentityVerificationRedirectURL } from './getIdentityVerificationRedirectURL';
import { getInvoices } from './getInvoices';
import { getKycCountries } from './getKycCountries';
import { getKycDocuments } from './getKycDocuments';
import { getKycInfo } from './getKycInfo';
import { getLastMessageInConversation } from './getLastMessageInConversation';
import { getListingBookablePeriods } from './getListingBookablePeriods';
import { getListingById } from './getListingById';
import { getListingExclusions } from './getListingExclusions';
import { getListingIcsCalendarLink } from './getListingIcsCalendarLink';
import { getListingsConfiguration } from './getListingsConfiguration';
import { getNotificationStatus } from './getNotificationStatus';
import { getNotifications } from './getNotifications';
import { getPaymentMethods } from './getPaymentMethods';
import { getPayoutMethod } from './getPayoutMethod';
import { getPayoutMethods } from './getPayoutMethods';
import { getPoiCommercial } from './getPoiCommercial';
import { getPois } from './getPois';
import { getProducts } from './getProducts';
import { getPropertyRegistrationInfo } from './getPropertyRegistrationInfo';
import { getQuickReplies } from './getQuickReplies';
import { getRealtimeToken } from './getRealtimeToken';
import { getRentalConditionsByConversationId } from './getRentalConditionsByConversationId';
import { getRentalConditionsById } from './getRentalConditionsById';
import { getRentalConditionsEstimate } from './getRentalConditionsEstimate';
import { getReportedUser } from './getReportedUser';
import { getReviewSurvey } from './getReviewSurvey';
import { getReviewSurveys } from './getReviewSurveys';
import { getSavedSearches } from './getSavedSearches';
import { getSharedDocuments } from './getSharedDocuments';
import { getSimilarListings } from './getSimilarListings';
import { getSitemaps } from './getSitemaps';
import { getSubscriptionProductsByHandle } from './getSubscriptionProductsByHandle';
import { getSubscriptions } from './getSubscriptions';
import { getSupportedMultiPayoutCurrencies } from './getSupportedCurrencies';
import { getTaxPayerDetails } from './getTaxPayerDetails';
import { getUniversities } from './getUniversities';
import { getUniversityInformation } from './getUniversityInformation';
import { getUser } from './getUser';
import { getUserDocumentViewUrl } from './getUserDocumentViewUrl';
import { getUserDocuments } from './getUserDocuments';
import { getUserInformation } from './getUserInformation';
import { getUserKycByUuid } from './getUserKycByUuid';
import { getUserKycs as getUserKYCs } from './getUserKycs';
import { getUserSubscriptions } from './getUserSubscriptions';
import { getUsers } from './getUsers';
import { initiateIDVerification } from './initiateIDVerification';
import { addFavoriteUnitType } from './inventory/addFavoriteUnitType';
import { addPropertyYoutubeVideo } from './inventory/addPropertyYoutubeVideo';
import { addYoutubeVideoUnitType } from './inventory/addYoutubeVideoUnitType';
import { confirmPropertyPhotoUpload } from './inventory/confirmPropertyPhotoUpload';
import { confirmPropertyVideoUpload } from './inventory/confirmPropertyVideoUpload';
import { confirmUnitTypePhotoUpload } from './inventory/confirmUnitTypePhotoUpload';
import { confirmUnitTypeVideoUpload } from './inventory/confirmUnitTypeVideoUpload';
import { createProperty } from './inventory/createProperty';
import { createUnitType } from './inventory/createUnitType';
import { deletePropertyPhoto } from './inventory/deletePropertyPhoto';
import { deletePropertyVideo } from './inventory/deletePropertyVideo';
import { deleteUnitType } from './inventory/deleteUnitType';
import { deleteUnitTypePhoto } from './inventory/deleteUnitTypePhoto';
import { deleteUnitTypeVideo } from './inventory/deleteUnitTypeVideo';
import { duplicateUnitType } from './inventory/duplicateUnitType';
import { getFavoriteUnitTypes } from './inventory/getFavoriteUnitTypes';
import { getPropertyById } from './inventory/getPropertyById';
import { getPropertyPhotos } from './inventory/getPropertyPhotos';
import { getUnitTypeBookablePeriods } from './inventory/getUnitTypeBookablePeriods';
import { getUnitTypeById } from './inventory/getUnitTypeById';
import { getUnitTypePhotos } from './inventory/getUnitTypePhotos';
import { getUnitTypes } from './inventory/getUnitTypes';
import { getUnitTypesSummary } from './inventory/getUnitTypesSummary';
import { publishUnitType } from './inventory/publishUnitType';
import { removeFavoriteUnitType } from './inventory/removeFavoriteUnitType';
import { requestPropertyPhotoUpload } from './inventory/requestPropertyPhotoUpload';
import { requestPropertyVideoUpload } from './inventory/requestPropertyVideoUpload';
import { requestUnitTypePhotoUpload } from './inventory/requestUnitTypePhotoUpload';
import { requestUnitTypeVideoUpload } from './inventory/requestUnitTypeVideoUpload';
import { surveyUnpublishUnitType } from './inventory/surveyUnpublishUnitType';
import { unpublishUnitType } from './inventory/unpublishUnitType';
import { updateProperty } from './inventory/updateProperty';
import { updatePropertyPhoto } from './inventory/updatePropertyPhoto';
import { updatePropertyPhotosOrder } from './inventory/updatePropertyPhotosOrder';
import { updateUnitType } from './inventory/updateUnitType';
import { updateUnitTypePhoto } from './inventory/updateUnitTypePhoto';
import { updateUnitTypePhotosOrder } from './inventory/updateUnitTypePhotosOrder';
import { linkDocumentToConversation } from './linkDocumentToConversation';
import { linkKycDocument } from './linkKycDocument';
import { makeSpecialOffer } from './makeSpecialOffer';
import { markAllNotificationsAsRead } from './markAllNotificationsAsRead';
import { markConversationAsRead } from './markConversationAsRead';
import { markNotificationAsRead } from './markNotificationAsRead';
import { cancelPayIn } from './payments/cancelPayIn';
import { confirmPayIn } from './payments/confirmPayIn';
import { confirmPaymentMethod } from './payments/confirmPaymentMethod';
import { createMultiBillingInformation } from './payments/createMultiBillingInformation';
import { createPayIn } from './payments/createPayIn';
import { createPaymentMethod } from './payments/createPaymentMethod';
import { createSettlement } from './payments/createSettlement';
import { deleteMultiBillingInformation } from './payments/deleteMultiBillingInformation';
import { deleteUserPaymentMethod } from './payments/deleteUserPaymentMethod';
import { getAllPayoutMethodRouting } from './payments/getAllPayoutMethodRouting';
import { getBillingInformation } from './payments/getBillingInformation';
import { getCommissionPlan } from './payments/getCommissionPlan';
import { getConversationCommissionInvoice } from './payments/getConversationCommissionInvoice';
import { getMultiBillingInformation } from './payments/getMultiBillingInformation';
import { getPayIn } from './payments/getPayIn';
import { getPayInTypes } from './payments/getPayInTypes';
import { getPayInsBySettlement } from './payments/getPayInsBySettlement';
import { getPaymentMethod } from './payments/getPaymentMethod';
import { getPaymentMethodTypes } from './payments/getPaymentMethodTypes';
import { getPayout } from './payments/getPayout';
import { getPayoutMethodRouting } from './payments/getPayoutMethodRouting';
import { getPayoutMethodRoutingPaymentReq } from './payments/getPayoutMethodRoutingPaymentReq';
import { getPayoutMethodsV2 } from './payments/getPayoutMethodsV2';
import { getPayoutTransactions } from './payments/getPayoutTransactions';
import { getSettlement } from './payments/getSettlement';
import { getUserPaymentMethods } from './payments/getUserPaymentMethods';
import { linkListingToBillingInformation } from './payments/linkListingToBillingInformation';
import { linkListingToDefaultBillingInformation } from './payments/linkListingToDefaultBillingInformation';
import { linkPayoutMethod } from './payments/linkPayoutMethod';
import { linkUnitTypeToDefaultPayoutMethod } from './payments/linkUnitTypeToDefaultPayoutMethod';
import { linkUnitTypeToPayoutMethod } from './payments/linkUnitTypeToPayoutMethod';
import { setBillingAddressAsDefault } from './payments/setBillingAddressAsDefault';
import { updateBillingInformation } from './payments/updateBillingInformation';
import { updateMultiBillingInformation } from './payments/updateMultiBillingInformation';
import { updatePayoutMethodRouting } from './payments/updatePayoutMethodRouting';
import { postUserDocumentRequest } from './postUserDocumentRequest';
import { postUserKyc as postUserKYC } from './postUserKyc';
import { putUserKyc as putUserKYC } from './putUserKyc';
import { addQualificationRequirements } from './qualifications/addQualificationRequirements';
import { addQualificationRequirementsDefaults } from './qualifications/addQualificationRequirementsDefaults';
import { deleteQualificationRequirement } from './qualifications/deleteQualificationRequirement';
import { deleteQualificationRequirementsDefaults } from './qualifications/deleteQualificationRequirementsDefaults';
import { getQualificationRequirements } from './qualifications/getQualificationRequirements';
import { getQualificationRequirementsByUnitType } from './qualifications/getQualificationRequirementsByUnitType';
import { getQualificationRequirementsDefaults } from './qualifications/getQualificationRequirementsDefaults';
import { qualificationQualifyUser } from './qualifications/qualificationQualifyUser';
import { updateQualificationRequirementsByUnitType } from './qualifications/updateQualificationRequirementsByUnitType';
import { updateQualificationRequirementsDefaults } from './qualifications/updateQualificationRequirementsDefaults';
import { reactivateSubscription } from './reactivateSubscription';
import { recoverAccount } from './recoverAccount';
import { removePayoutMethod } from './removePayoutMethod';
import { removeSavedSearch } from './removeSavedSearch';
import { replacesListingExclusions } from './replacesListingExclusions';
import { reportUser } from './reportUser';
import { requestActivationEmail } from './requestActivationEmail';
import { requestBookingCancellation } from './requestBookingCancellation';
import { requestBookingModification } from './requestBookingModification';
import { requestCode } from './requestCode';
import { resetPassword } from './resetPassword';
import { saveSearch } from './saveSearch';
import { searchCity } from './searchCity';
import { sendMessage } from './sendMessage';
import { sendTouchPointsList } from './sendTouchPointsList';
import { setBookingGuarantor } from './setBookingGuarantor';
import { setPayoutMethodAsDefault } from './setPayoutMethodAsDefault';
import { addTenantReviewReply } from './surveyReviews/addTenantReviewReply';
import { editTenantReviewReply } from './surveyReviews/editTenantReviewReply';
import { getReviewAdvertiserOverallRating } from './surveyReviews/getReviewAdvertiserOverallRating';
import { getReviewAdvertiserReports } from './surveyReviews/getReviewAdvertiserReports';
import { getReviewAdvertiserSurveys } from './surveyReviews/getReviewAdvertiserSurveys';
import { getReviewReport } from './surveyReviews/getReviewReport';
import { toggleArchiveConversation } from './toggleArchiveConversation';
import { toggleFavoriteConversation } from './toggleFavoriteConversation';
import { translateUnitTypeDescription } from './translateListingDescription';
import { updateKycStatus } from './updateKycStatus';
import { updatePayoutMethod } from './updatePayoutMethod';
import { updatePropertyRegistrationInfo } from './updatePropertyRegistrationInfo';
import { updateQuickReply } from './updateQuickReply';
import { updateTaxPayer } from './updateTaxPayer';
import { updateTenantPromoCode } from './updateTenantPromoCode';
import { updateUnitICS } from './updateUnitICS';
import { updateUser } from './updateUser';
import { updateUserSubscriptions } from './updateUserSubscriptions';
import { uploadAvatar } from './uploadAvatar';
import { uploadAvatarByUrl } from './uploadAvatarByUrl';
import { validatePhone } from './validatePhone';
import { verifyCode } from './verifyCode';
import { verifyManually } from './verifyManually';
import { getManagedGroupUsers } from './getManagedGroupUsers';

interface FetchClient {
  fetch: (url: string, options: RequestInit) => Promise<Response>;
}

export const apiV2 = (fetchClient: FetchClient) => {
  const makeCall = createMakeCall(fetchClient);

  return {
    acceptOrDeclineBookingModificationRequest:
      acceptOrDeclineBookingModificationRequest(makeCall),
    acceptTenant: acceptTenant(makeCall),
    activateUser: activateUser(makeCall),
    addFavoriteUnitType: addFavoriteUnitType(makeCall),
    addPayoutMethod: addPayoutMethod(makeCall),
    addPoiDraft: addPoiDraft(makeCall),
    addQualificationRequirements: addQualificationRequirements(makeCall),
    addQualificationRequirementsDefaults:
      addQualificationRequirementsDefaults(makeCall),
    addReviewReportObjection: addReviewReportObjection(makeCall),
    addReviewSurveyReport: addReviewSurveyReport(makeCall),
    addSavedSearch: addSavedSearch(makeCall),
    addTenantReviewReply: addTenantReviewReply(makeCall),
    addPropertyYoutubeVideo: addPropertyYoutubeVideo(makeCall),
    addYoutubeVideoUnitType: addYoutubeVideoUnitType(makeCall),
    cancelConversation: cancelConversation(makeCall),
    cancelPayIn: cancelPayIn(makeCall),
    cancelSubscription: cancelSubscription(makeCall),
    changeConversationDate: changeConversationDate(makeCall),
    chargeTenant: chargeTenant(makeCall),
    confirmPayIn: confirmPayIn(makeCall),
    confirmPaymentMethod: confirmPaymentMethod(makeCall),
    confirmPayoutMethod: confirmPayoutMethod(makeCall),
    confirmPropertyPhotoUpload: confirmPropertyPhotoUpload(makeCall),
    confirmPropertyVideoUpload: confirmPropertyVideoUpload(makeCall),
    confirmUnitTypePhotoUpload: confirmUnitTypePhotoUpload(makeCall),
    confirmUnitTypeVideoUpload: confirmUnitTypeVideoUpload(makeCall),
    confirmUser: confirmUser(makeCall),
    confirmUserDocumentUpload: confirmUserDocumentUpload(makeCall),
    createBookingRequest: createBookingRequest(makeCall),
    createConversation: createConversation(makeCall),
    createMultiBillingInformation: createMultiBillingInformation(makeCall),
    createPayIn: createPayIn(makeCall),
    createPaymentIntent: createPaymentIntent(makeCall),
    createPaymentMethod: createPaymentMethod(makeCall),
    createProperty: createProperty(makeCall),
    createPropertyRegistrationInfo: createPropertyRegistrationInfo(makeCall),
    createProspectiveLead: createProspectiveLead(makeCall),
    createQuickReply: createQuickReply(makeCall),
    createSettlement: createSettlement(makeCall),
    createSubscription: createSubscription(makeCall),
    createTaxPayer: createTaxPayer(makeCall),
    createTaxPayerTags: createTaxPayerTags(makeCall),
    createUnitType: createUnitType(makeCall),
    deletePropertyPhoto: deletePropertyPhoto(makeCall),
    deletePropertyVideo: deletePropertyVideo(makeCall),
    declineConversation: declineConversation(makeCall),
    deleteConversationAttachment: deleteConversationAttachment(makeCall),
    deleteKycDocument: deleteKycDocument(makeCall),
    deleteListingExclusionReminder: deleteListingExclusionReminder(makeCall),
    deleteMultiBillingInformation: deleteMultiBillingInformation(makeCall),
    deletePaymentMethod: deletePaymentMethod(makeCall),
    deleteQualificationRequirement: deleteQualificationRequirement(makeCall),
    deleteQualificationRequirementsDefaults:
      deleteQualificationRequirementsDefaults(makeCall),
    deleteQuickReply: deleteQuickReply(makeCall),
    deleteUnitType: deleteUnitType(makeCall),
    deleteUnitTypePhoto: deleteUnitTypePhoto(makeCall),
    deleteUnitTypeVideo: deleteUnitTypeVideo(makeCall),
    deleteUser: deleteUser(makeCall),
    deleteUserDocument: deleteUserDocument(makeCall),
    duplicateUnitType: duplicateUnitType(makeCall),
    editTenantReviewReply: editTenantReviewReply(makeCall),
    editUserDocument: editUserDocument(makeCall),
    finalizeBooking: finalizeBooking(makeCall),
    getAllPayoutMethodRouting: getAllPayoutMethodRouting(makeCall),
    getAverageRent: getAverageRent(makeCall),
    getBillingInvoiceURL: getBillingInvoiceURL(makeCall),
    getBillingInformation: getBillingInformation(makeCall),
    getChangeRequestByConversationId:
      getChangeRequestByConversationId(makeCall),
    getCities: getCities(makeCall),
    getCitiesNearby: getCitiesNearby(makeCall),
    getCommissionPlan: getCommissionPlan(makeCall),
    getCommissionPlans: getCommissionPlans(makeCall),
    getConversation: getConversation(makeCall),
    getConversationByToken: getConversationByToken(makeCall),
    getConversationCommissionInvoice:
      getConversationCommissionInvoice(makeCall),
    getConversationCounters: getConversationCounters(makeCall),
    getConversationMessages: getConversationMessages(makeCall),
    getConversationPayoutInfo: getConversationPayoutInfo(makeCall),
    getConversations: getConversations(makeCall),
    getCurrencyEuroRates: getCurrencyEuroRates(makeCall),
    getEngagements: getEngagements(makeCall),
    getFavoriteUnitTypes: getFavoriteUnitTypes(makeCall),
    getFeaturedCities: getFeaturedCities(makeCall),
    getFullUrl: getFullUrl(makeCall),
    createIdentityVerificationRedirectURL:
      createIdentityVerificationRedirectURL(makeCall),
    getIdentityVerificationRedirectURL:
      getIdentityVerificationRedirectURL(makeCall),
    getInvoices: getInvoices(makeCall),
    getKycCountries: getKycCountries(makeCall),
    getKycDocuments: getKycDocuments(makeCall),
    getKycInfo: getKycInfo(makeCall),
    getLastMessageInConversation: getLastMessageInConversation(makeCall),
    getListingBookablePeriods: getListingBookablePeriods(makeCall),
    getListingById: getListingById(makeCall),
    getListingExclusions: getListingExclusions(makeCall),
    getListingIcsCalendarLink: getListingIcsCalendarLink(makeCall),
    getListingsConfiguration: getListingsConfiguration(makeCall),
    getMultiBillingInformation: getMultiBillingInformation(makeCall),
    getNotifications: getNotifications(makeCall),
    getNotificationStatus: getNotificationStatus(makeCall),
    getPayIn: getPayIn(makeCall),
    getPayInsBySettlement: getPayInsBySettlement(makeCall),
    getPayInTypes: getPayInTypes(makeCall),
    getPaymentMethod: getPaymentMethod(makeCall),
    getPaymentMethods: getPaymentMethods(makeCall),
    getPaymentMethodTypes: getPaymentMethodTypes(makeCall),
    getPayout: getPayout(makeCall),
    getPayoutMethod: getPayoutMethod(makeCall),
    getPayoutMethodRouting: getPayoutMethodRouting(makeCall),
    getPayoutMethodRoutingPaymentReq:
      getPayoutMethodRoutingPaymentReq(makeCall),
    getPayoutMethods: getPayoutMethods(makeCall),
    getPayoutMethodsV2: getPayoutMethodsV2(makeCall),
    getPayoutTransactions: getPayoutTransactions(makeCall),
    getPoiCommercial: getPoiCommercial(makeCall),
    getPois: getPois(makeCall),
    getPropertyById: getPropertyById(makeCall),
    getPropertyPhotos: getPropertyPhotos(makeCall),
    getUniversityInformation: getUniversityInformation(makeCall),
    getProducts: getProducts(makeCall),
    getPropertyRegistrationInfo: getPropertyRegistrationInfo(makeCall),
    getQualificationRequirements: getQualificationRequirements(makeCall),
    getQualificationRequirementsByUnitType:
      getQualificationRequirementsByUnitType(makeCall),
    getQualificationRequirementsDefaults:
      getQualificationRequirementsDefaults(makeCall),
    getQuickReplies: getQuickReplies(makeCall),
    getRealtimeToken: getRealtimeToken(makeCall),
    getRentalConditionsByConversationId:
      getRentalConditionsByConversationId(makeCall),
    getRentalConditionsById: getRentalConditionsById(makeCall),
    getRentalConditionsEstimate: getRentalConditionsEstimate(makeCall),
    getReportedUser: getReportedUser(makeCall),
    getReviewAdvertiserOverallRating:
      getReviewAdvertiserOverallRating(makeCall),
    getReviewAdvertiserReports: getReviewAdvertiserReports(makeCall),
    getReviewAdvertiserSurveys: getReviewAdvertiserSurveys(makeCall),
    getReviewReport: getReviewReport(makeCall),
    getReviewSurvey: getReviewSurvey(makeCall),
    getReviewSurveys: getReviewSurveys(makeCall),
    unsubscribeFromSurvey: unsubscribeFromSurvey(makeCall),
    getSavedSearches: getSavedSearches(makeCall),
    getSettlement: getSettlement(makeCall),
    getSharedDocuments: getSharedDocuments(makeCall),
    getSimilarListings: getSimilarListings(makeCall),
    getSitemaps: getSitemaps(makeCall),
    getSubscriptionProductsByHandle: getSubscriptionProductsByHandle(makeCall),
    getSubscriptions: getSubscriptions(makeCall),
    getSupportedMultiPayoutCurrencies:
      getSupportedMultiPayoutCurrencies(makeCall),
    getTaxPayerDetails: getTaxPayerDetails(makeCall),
    getUnitTypeBookablePeriods: getUnitTypeBookablePeriods(makeCall),
    getUnitTypeById: getUnitTypeById(makeCall),
    getUnitTypePhotos: getUnitTypePhotos(makeCall),
    getUnitTypes: getUnitTypes(makeCall),
    getUnitTypesSummary: getUnitTypesSummary(makeCall),
    getUniversities: getUniversities(makeCall),
    getUser: getUser(makeCall),
    getUserDocuments: getUserDocuments(makeCall),
    getUserDocumentViewUrl: getUserDocumentViewUrl(makeCall),
    getUserInformation: getUserInformation(makeCall),
    getUserKycByUuid: getUserKycByUuid(makeCall),
    getUserKYCs: getUserKYCs(makeCall),
    getUserPaymentMethods: getUserPaymentMethods(makeCall),
    deleteUserPaymentMethod: deleteUserPaymentMethod(makeCall),
    getUsers: getUsers(makeCall),
    getUserSubscriptions: getUserSubscriptions(makeCall),
    initiateIDVerification: initiateIDVerification(makeCall),
    linkDocumentToConversation: linkDocumentToConversation(makeCall),
    linkKycDocument: linkKycDocument(makeCall),
    linkListingToBillingInformation: linkListingToBillingInformation(makeCall),
    linkListingToDefaultBillingInformation:
      linkListingToDefaultBillingInformation(makeCall),
    linkPayoutMethod: linkPayoutMethod(makeCall),
    linkUnitTypeToDefaultPayoutMethod:
      linkUnitTypeToDefaultPayoutMethod(makeCall),
    linkUnitTypeToPayoutMethod: linkUnitTypeToPayoutMethod(makeCall),
    makeSpecialOffer: makeSpecialOffer(makeCall),
    markAllNotificationsAsRead: markAllNotificationsAsRead(makeCall),
    markConversationAsRead: markConversationAsRead(makeCall),
    markNotificationAsRead: markNotificationAsRead(makeCall),
    postUserDocumentRequest: postUserDocumentRequest(makeCall),
    postUserKYC: postUserKYC(makeCall),
    publishUnitType: publishUnitType(makeCall),
    putUserKYC: putUserKYC(makeCall),
    qualificationQualifyUser: qualificationQualifyUser(makeCall),
    reactivateSubscription: reactivateSubscription(makeCall),
    recoverAccount: recoverAccount(makeCall),
    removeFavoriteUnitType: removeFavoriteUnitType(makeCall),
    removePayoutMethod: removePayoutMethod(makeCall),
    removeSavedSearch: removeSavedSearch(makeCall),
    replacesListingExclusions: replacesListingExclusions(makeCall),
    reportUser: reportUser(makeCall),
    requestActivationEmail: requestActivationEmail(makeCall),
    requestBookingModification: requestBookingModification(makeCall),
    requestBookingCancellation: requestBookingCancellation(makeCall),
    requestCode: requestCode(makeCall),
    requestPropertyPhotoUpload: requestPropertyPhotoUpload(makeCall),
    requestPropertyVideoUpload: requestPropertyVideoUpload(makeCall),
    requestUnitTypePhotoUpload: requestUnitTypePhotoUpload(makeCall),
    requestUnitTypeVideoUpload: requestUnitTypeVideoUpload(makeCall),
    resetPassword: resetPassword(makeCall),
    saveSearch: saveSearch(makeCall),
    searchCity: searchCity(makeCall),
    sendMessage: sendMessage(makeCall),
    sendTouchPointsList: sendTouchPointsList(makeCall),
    setBillingAddressAsDefault: setBillingAddressAsDefault(makeCall),
    setPayoutMethodAsDefault: setPayoutMethodAsDefault(makeCall),
    surveyUnpublishUnitType: surveyUnpublishUnitType(makeCall),
    toggleArchiveConversation: toggleArchiveConversation(makeCall),
    toggleFavoriteConversation: toggleFavoriteConversation(makeCall),
    translateUnitTypeDescription: translateUnitTypeDescription(makeCall),
    unpublishUnitType: unpublishUnitType(makeCall),
    updateBillingInformation: updateBillingInformation(makeCall),
    updateKycStatus: updateKycStatus(makeCall),
    updateUnitICS: updateUnitICS(makeCall),
    updateMultiBillingInformation: updateMultiBillingInformation(makeCall),
    updatePayoutMethod: updatePayoutMethod(makeCall),
    updateTenantPromoCode: updateTenantPromoCode(makeCall),
    updatePayoutMethodRouting: updatePayoutMethodRouting(makeCall),
    updateProperty: updateProperty(makeCall),
    updatePropertyPhoto: updatePropertyPhoto(makeCall),
    updatePropertyPhotosOrder: updatePropertyPhotosOrder(makeCall),
    updatePropertyRegistrationInfo: updatePropertyRegistrationInfo(makeCall),
    updateQualificationRequirementsByUnitType:
      updateQualificationRequirementsByUnitType(makeCall),
    updateQualificationRequirementsDefaults:
      updateQualificationRequirementsDefaults(makeCall),
    updateQuickReply: updateQuickReply(makeCall),
    updateTaxPayer: updateTaxPayer(makeCall),
    updateUnitType: updateUnitType(makeCall),
    updateUnitTypePhoto: updateUnitTypePhoto(makeCall),
    updateUnitTypePhotosOrder: updateUnitTypePhotosOrder(makeCall),
    updateUser: updateUser(makeCall),
    updateUserSubscriptions: updateUserSubscriptions(makeCall),
    uploadAvatar: uploadAvatar(makeCall),
    uploadAvatarByUrl: uploadAvatarByUrl(makeCall),
    validatePhone: validatePhone(makeCall),
    verifyCode: verifyCode(makeCall),
    verifyManually: verifyManually(makeCall),
    setBookingGuarantor: setBookingGuarantor(makeCall),
    getManagedGroupUsers: getManagedGroupUsers(makeCall),
  };
};
