import { AnyAction } from 'redux';

import { OPEN, CLOSE } from '../actions';

export const initialState = {
  openModal: null,
  openQueue: [],
};

export function reducer(
  state = initialState,
  action: AnyAction = { type: undefined },
) {
  switch (action.type) {
    case OPEN:
      if (state.openModal) {
        return {
          ...state,
          openQueue: [...state.openQueue, action.payload.modal],
        };
      }

      return { ...state, openModal: action.payload.modal };

    case CLOSE:
      if (state.openModal) {
        const [head, ...tail] = state.openQueue;

        return {
          ...state,
          openModal: head,
          openQueue: tail,
        };
      }

      return {
        ...state,
        openQueue: state.openQueue.filter(
          modal => modal !== action.payload.modal,
        ),
      };

    default:
      return state;
  }
}
