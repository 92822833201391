import { MakeCall } from '../createMakeCall';
import { PropertyVideo } from '../types/inventory/Property';

const confirmPropertyVideoUpload =
  (makeCall: MakeCall) => (propertyId: number, videoId: number) =>
    makeCall<PropertyVideo[]>(
      'PUT',
      `/api/v2/property/${propertyId}/video/${videoId}/confirm`,
    );
export { confirmPropertyVideoUpload };
