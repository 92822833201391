import { IntegrationNames } from '@hbf/analytics/lib/constants';

export const ONLY_ROCKERBOX_INTEGRATION = {
  All: false,
  [IntegrationNames.Rockerbox]: true,
};

export const INTEGRATIONS_WITHOUT_ROCKERBOX = {
  All: true,
  [IntegrationNames.Rockerbox]: false,
};
