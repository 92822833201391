import { MakeCall } from '../createMakeCall';

export interface PropertyPhotoUploadRecord {
  url: string;
  contentType: string;
  expires: string;
  photoId: number;
  photoUuid: string;
}

const requestPropertyPhotoUpload =
  (makeCall: MakeCall) => (propertyId: number) =>
    makeCall<PropertyPhotoUploadRecord>(
      'POST',
      `/api/v2/property/${propertyId}/photos/request`,
    );

export { requestPropertyPhotoUpload };
