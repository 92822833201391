import { MakeCall } from './createMakeCall';
import { Unit } from './types/inventory/Unit';

export interface Query {
  /**
   * @description comma-separated string with possible values: `exclusions`, `exclusionsExtended`, `bookablePeriods`, `conversationCounters`, `lastMonthConversationCount`, `photos`, `videos`, `costs`
   */
  expand?: string;
  lang?: string;
  moveInDate?: string;
  moveOutDate?: string;
}

const getListingById =
  (makeCall: MakeCall) => (listingId: number, query?: Query) =>
    makeCall<Unit>('GET', `/api/v2/listing/${listingId}`, {
      query,
    });

export { getListingById };
