import { MakeCall } from '../createMakeCall';
import { PropertyVideoUploadRecord } from '../types/inventory/Property';

const requestPropertyVideoUpload =
  (makeCall: MakeCall) => (propertyId: number) =>
    makeCall<PropertyVideoUploadRecord>(
      'POST',
      `/api/v2/property/${propertyId}/videos/request`,
    );
export { requestPropertyVideoUpload };
