import { ThunkMiddleware } from 'redux-thunk';

import { send } from '../actions';
import { Actions, SESSION_STORAGE_KEY } from '../constants';

const persistentMiddleware: ThunkMiddleware = store => next => action => {
  if (action.type === Actions.SEND && action.payload.persistent) {
    try {
      global.sessionStorage.setItem(
        SESSION_STORAGE_KEY,
        JSON.stringify(action.payload),
      );
    } catch (error) {
      // ignore error
    }
  } else if (action.type === Actions.SEND_PERSISTENT) {
    try {
      const alertString = global.sessionStorage.getItem(SESSION_STORAGE_KEY);
      const alert = alertString ? JSON.parse(alertString) : null;

      if (alert) {
        global.sessionStorage.removeItem(SESSION_STORAGE_KEY);
        // @ts-expect-error - TSMigration
        store.dispatch(send({ ...alert, persistent: false }));
      }
    } catch (error) {
      // ignore error
    }
  }

  return next(action);
};

export { persistentMiddleware };
