type Options = {
  retries?: number;
  interval?: number;
};

export function autoRetry<R>(
  fn: () => Promise<R>,
  { retries = 3, interval = 500 }: Options = {},
) {
  // https://www.bitahoy.com/blog/post/fixing-chunk-load-errors-in-webpack-and-react-js
  const storageKey = `load-latest-chunk-${btoa(fn.toString())}`;
  const isBrowser = typeof window !== 'undefined';

  const hasRefreshed = isBrowser
    ? Boolean(window?.sessionStorage?.getItem(storageKey))
    : true; // in non-browser environments, skip page refresh logic and assume chunk has been refreshed

  return new Promise<R>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retries === 1) {
            if (
              isBrowser &&
              error?.name === 'ChunkLoadError' &&
              !hasRefreshed
            ) {
              window?.sessionStorage?.setItem(storageKey, 'true');

              console.log(
                'Refreshing the page once due to chunk load error...',
              );
              window.location.reload();
              return;
            }

            reject(error);
            return;
          }

          autoRetry(fn, {
            retries: retries - 1,
            interval,
          }).then(resolve, reject);
        }, interval);
      });
  });
}
