import { isHaMobileApp, getHaMobileAppMetadata } from '@ha/core/MobileApp';
import {
  IdentifyTraits,
  getAnonymousId,
  registerSuperProperties,
  registerSuperPropertiesOnce,
} from '@hbf/analytics';
import { isOneTrustInitialised } from '@hbf/cookie-consent';

import { clearUserData } from 'ha/modules/AuthLogic/clearUserData';
import { IMPERSONATION_STATE_QUERY_PARAMETER } from 'ha/modules/AuthLogic/constants';
import { getUserId } from 'ha/modules/AuthLogic/selectors';
import {
  getIsMobile,
  getIsTablet,
  getCurrentScreenSize,
} from 'ha/modules/UI/selectors';
import { Thunk } from 'ha/myredux/types';
import { CookieConsent } from 'ha/services/analytics/types';

import { getCampaignParams } from '../helpers/getCampaignParams';
import { getUtmSource } from '../helpers/getUtmSource';

import { identifyUser } from './identifyUser';

export interface SetupAnalyticsArgs {
  url: URL;
  referrer: string;
}

export const setupAnalytics =
  ({ url, referrer }: SetupAnalyticsArgs): Thunk =>
  (dispatch, getState, { analytics, cookie }) => {
    analytics.onReady((cookieConsent: CookieConsent) => {
      const urlObject = new URL(window.location.href);

      // skip analytics identification for impersonation action
      if (urlObject.searchParams.get(IMPERSONATION_STATE_QUERY_PARAMETER)) {
        return;
      }

      const state = getState();

      const userId = getUserId(state);

      if (!userId && getAnonymousId()?.startsWith('u')) {
        // unauthenticated access with anonymous id is user alias id
        // => can not be right => reset analytics
        clearUserData(cookie);
      }

      const { isCookieConsentEnabled } = cookieConsent;

      const source = getUtmSource(url, referrer);
      const campaignParams = getCampaignParams(url.searchParams);
      const isMobile = getIsMobile(state);
      const isTablet = getIsTablet(state);
      const screenSize = getCurrentScreenSize(state);
      const userTraits: IdentifyTraits = {
        isCookieConsentEnabled,
        ...campaignParams.params,
        ...campaignParams.firstParams,
        Source: source,
      };

      registerSuperProperties({
        ...campaignParams.params,
        CurrentSource: source,
        isMobile,
        isTablet,
        isDesktop: !isMobile && !isTablet,
        screenSize,
        content_type: 'home_listing',
        isOneTrustInitialised: isOneTrustInitialised(),
        isHaMobileApp: isHaMobileApp(),
        haMobileAppMetadata: getHaMobileAppMetadata(),
      });
      registerSuperPropertiesOnce({ Source: source });

      dispatch(identifyUser(userTraits));
    });
  };
