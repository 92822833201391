import { MakeCall } from './createMakeCall';
import { SharedDocumentsResponse } from './types/UserDocument';

const getSharedDocuments =
  (makeCall: MakeCall) => (ownerId: number, conversationId?: number) =>
    makeCall<SharedDocumentsResponse>(
      'GET',
      `/api/v2/qualification/documents-shared?ownerId=${ownerId}&conversationId=${conversationId}`,
    );

export { getSharedDocuments };
